import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import axios, { all } from "axios"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import Form from "react-bootstrap/Form"
import "./AddTags.css"
import Cookies from "js-cookie"
import { useForm } from "react-hook-form"
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

const AddTags = () => {
	const navigate = useNavigate();
	const [name, setName] = useState(""); // Estado del nombre que el usuario ingresa
	const [measure, setMeasure] = useState("");
	const [category, setCategory] = useState();
	const [allProviders, setAllProviders] = useState([]);
	const [provider, setProvider] = useState("");
	const [isProducts, setIsProducts] = useState();
	const [allUnits, setAllUnits] = useState([]);
	const [allCategories, setAllCategories] = useState([]);
	const [variable, setVariable] = useState(false);
	const [errorBool, setErrorBool] = useState(false);
	const [avisomsg, setAvisomsg] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const token = Cookies.get("token");

	useEffect(() => {
		(async () => {
			await axios
				.get(`${BACK_APP_URI}/unidades`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then((result) => {
					setAllUnits(result.data.units);
				});
			await axios
				.get(`${BACK_APP_URI}/categories`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then((result) => {
					setAllCategories(result.data.categories);
				});

			// Obtiene proveedores
			await axios
				.get(`${BACK_APP_URI}/proveedor`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then((result) => {
					setAllProviders(result.data.provider);
				});
		})();
	}, []);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm();

	// Función para procesar el nombre antes de enviarlo al backend
	const processNameForBackend = (name) => {
		const processedName = name.replace(/[^a-zA-Z0-9]/g, ''); // Nombre sin espacios ni caracteres especiales
		const displayName = name.replace(/([a-z])([A-Z0-9])/g, '$1 $2'); // Formato con espacios
		return { processedName, displayName };
	};


	const onSubmit = async () => {
		const { processedName, displayName } = processNameForBackend(name);
		try {
			setIsLoading(true);
			await axios.post(`${BACK_APP_URI}/tags/add`, {
				name: processedName, // Para escaneo
				displayName,         // Para mostrar
				measure,
				isProducts,
				category,
				provider
			});

			// Feedback de éxito
			setVariable(true);
			setErrorBool(false);
			setAvisomsg('Etiqueta creada exitosamente');
			setTimeout(() => {
				setVariable(false);
				navigate('/tags');
			}, 1800);
		} catch (error) {
			console.log(error);
			setErrorBool(true);
			setVariable(true);
			setAvisomsg(error.response?.data?.msg || 'Error al crear la etiqueta');
			setTimeout(() => {
				setVariable(false);
				setIsLoading(false);
			}, 3200);
		}
	};


	// Validación del nombre para permitir letras, números, espacios y caracteres especiales
	const validateName = (value) => {
		const regex = /^[a-zA-Z0-9\s\W_]*$/; // Permitir letras, números, espacios y caracteres especiales
		if (!regex.test(value)) {
			setError("name", { type: "invalid" });
			return false;
		}
		return true;
	};





	return (
		<div className="main-container">
			<div className="edit-tag-header">
				<h1>Agregar Etiqueta</h1>
				<div className="divider-tags"></div>
				<div className="edit-tag-tarjet">
					<h2 className="h1-unidad">Etiqueta</h2>
					<Form
						className="edit-tag-form"
						onSubmit={handleSubmit(onSubmit)}
					>
						<div className="edit-tag-floating">
							<FloatingLabel label="Nombre">
								<Form.Control
									className="edit-tag-form-control"
									placeholder="name"
									name="name"
									type="text"
									{...register("name", {
										required: true,
										maxLength: 40,
										validate: validateName
									})}
									value={name}
									onChange={(e) => setName(e.target.value)} // Actualizar el estado sin espacios
								/>
								{errors.name?.type === "required" && <p className="error">Campo requerido</p>}
								{errors.name?.type === "maxLength" && <p className="error">Cantidad mínima de caracteres es de 40</p>}
								{errors.name?.type === "validate" && <p className="error">Nombre inválido</p>}
							</FloatingLabel>


							<FloatingLabel className="add-tag-select" label="Tipo de unidad">

								<Form.Select
									className="edit-tag-form-select"
									{...register("unitType", {
										required: true,
									})}
									onChange={e => {
										setMeasure(e.target.value)
									}}
								>
									<option value="">Seleccionar</option>
									{allUnits.map(unit => {
										return (
											<option
												key={unit._id}
												value={unit._id}
											>
												{unit.name}
											</option>
										)
									})}
								</Form.Select>
								{errors.unitType?.type === "required" && <p class="error">Campo requerido </p>}
							</FloatingLabel>

							<FloatingLabel label="Proveedor">
								<Form.Select
									className="edit-tag-form-select"
									{...register("provider", {
										required: true,
									})}
									value={provider}
									onChange={e => {
										if (e.target.value === "add") {
											navigate("/provider/add")
										}
										setProvider(e.target.value)
									}}
								>
									<option
										value=""
										disabled
									>
										Seleccionar
									</option>

									{allProviders.map(provider => (
										<option
											key={provider._id}
											value={provider._id}
										>
											{provider.name}
										</option>
									))}
									<option value="add">Agregar proveedor</option>
								</Form.Select>
								{errors.provider?.type === "required" && <p className="error">Campo requerido</p>}
							</FloatingLabel>
							<div className="check-tag">
								<h3 className="text-check">Tipo de etiqueta</h3>
								<div className="checkbox">
									<Form.Group name="isProducts">
										<Form.Check
											type="radio"
											label="Bioquímicos"
											{...register("isProducts", {
												required: true,
											})}
											onChange={e => {
												setIsProducts(true)
											}}
										/>
									</Form.Group>
									<Form.Group name="isProducts">
										<Form.Check
											type="radio"
											label="Varios"
											{...register("isProducts", {
												required: true,
											})}
											onChange={e => {
												setIsProducts(false)
											}}
										/>
									</Form.Group>
								</div>
							</div>
							{errors.isProducts?.type === "required" && <p class="error">Campo requerido </p>}

							{isProducts === false && (
								<FloatingLabel
									label="Categoría"
									className="mb-3"
								>
									<Form.Select
										className="edit-tag-form-select"
										{...register("category", {
											required: true,
										})}
										onChange={e => {
											setCategory(e.target.value)
										}}
									>
										<option value="">Seleccionar</option>
										{allCategories.map(cat => {
											return (
												<option
													key={cat._id}
													value={cat._id}
												>
													{cat.name}
												</option>
											)
										})}
									</Form.Select>
									{errors.category?.type === "required" && <p class="error">Campo requerido </p>}
								</FloatingLabel>
							)}

						</div>

						<div className="edit-tag-btn">
							<div className="btn-edit">
								<button
									children="Cancelar"
									type="submit"
									className="button-edit-text"
									onClick={() => {
										navigate("/tags")
									}}
								/>
								<button
									children="Guardar"
									type="submit"
									className="button-edit"
									disabled={isLoading}
								/>
							</div>
						</div>
					</Form>
				</div>

				<div />
			</div>
			{variable && (
				<AnimacionSuccessful
					errorBool={errorBool}
					avisomsg={avisomsg}
				/>
			)}
		</div>
	)
}
export default AddTags
