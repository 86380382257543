import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import axios from "axios"
import Form from "react-bootstrap/Form"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import Button from "react-bootstrap/Button"
import "./EditProducts.css"
import { useForm } from "react-hook-form"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Cookies from "js-cookie"
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful"
import TagSelectorForm from "../Components/TagSelectorForm"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

const EditProducts = () => {
	const { id } = useParams()
	const navigate = useNavigate()

	const [code, setCode] = useState("")
	const [name, setName] = useState("")
	const [ubn, setUbn] = useState(null)
	const [brand, setBrand] = useState("")
	const [unitType, setUnitType] = useState("")
	const [provider, setProvider] = useState("")
	const [tag, setTag] = useState(null) // Dejarlo en null para que el middleware funcione
	const [weightOrVolume, setWeightOrVolume] = useState(undefined)

	const [allUnits, setAllUnits] = useState([]);

	const [unitTypes, setUnitTypes] = useState([])
	const [providers, setProviders] = useState([])

	const [variable, setVariable] = useState(false)
	const [errorBool, setErrorBool] = useState(false)
	const [avisomsg, setAvisomsg] = useState("")

	const [showWeightOrVolumeInput, setShowWeightOrVolumeInput] = useState(false)

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm()

	useEffect(() => {
		async function getDataProduct() {
			await axios.get(`${BACK_APP_URI}/productos/${id}`).then(result => {
				const { code, name, brand, provider, ubn, unitType, tag, weightOrVolume } = result.data

				setCode(code)
				setValue("code", code)
				setName(name)
				setValue("name", name)
				setBrand(brand)
				setValue("brand", brand)
				setProvider(provider)
				setValue("provider", provider)
				setUbn(ubn)
				setValue("ubn", ubn)
				setUnitType(unitType)
				setValue("unitType", unitType)
				setWeightOrVolume(weightOrVolume)
				setValue("weightOrVolume", weightOrVolume)
				setTag(tag)
			})
		}
		async function getDataProviders() {
			const token = Cookies.get("token")
			await axios
				.get(`${BACK_APP_URI}/proveedor`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(result => {
					setProviders(result.data.provider)
				})
		}
		async function getDataUnits() {
			const token = Cookies.get("token")
			await axios
				.get(`${BACK_APP_URI}/unidades`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(result => {
					setAllUnits(result.data.units)
				})
		}
		getDataProviders()

		getDataUnits()
		getDataProduct()
	}, [])

	useEffect(() => {
		if (allUnits && unitType) {
			const unitAssigned = allUnits.find(unit => unit._id === unitType)

			if (unitAssigned && unitAssigned.name !== "Unitarios" && unitAssigned.name !== "Primaria") {
				setShowWeightOrVolumeInput(true)
			}
		}
	}, [allUnits, unitType])

	const onSubmit = async e => {
		e.preventDefault()
		axios({
			method: "put",
			url: `${BACK_APP_URI}/productos/edit/${id}`,
			data: {
				code: code,
				name: name,
				brand: brand,
				ubn: ubn,
				weightOrVolume: weightOrVolume,
				unitType: unitType,
				provider: provider,
				tag: tag,
			},
		})
			.then(data => {
				handleSuccessfulMessage("Producto Editado")
			})
			.catch(data => {
				handleUnsuccessfulMessage("Error al Editar Producto")
			})
	}

	const handleSuccessfulMessage = msg => {
		setVariable(true)
		setErrorBool(false)
		setAvisomsg(msg)
		setTimeout(() => {
			setVariable(false)
			navigate("/productos")
		}, 1200)
	}

	const handleUnsuccessfulMessage = msg => {
		setErrorBool(true)
		setVariable(true)
		setAvisomsg(msg)
		setTimeout(() => {
			setVariable(false)
		}, 1200)
	}

	return (
		<div className="edit-product">
			<div className="edit-product-target"> 
				<div className="header-edit-prod">
				<h1>Editar Producto</h1>
				<div className="dividers-edit-tag" /> 
				<div className="form-edit-prod-cont">
					<Form onSubmit={e => handleSubmit(onSubmit(e))}>
						<div className="form-edit-product">
							<FloatingLabel label="Codigo de producto">
								<Form.Control
									className="input--primary"
									placeholder="Codigo"
									name="Codigo"
									type="text"
									value={code}
									{...register("code", {
										required: true,
										maxLength: 40,
									})}
									onChange={e => {
										setCode(e.target.value)
									}}
								/>
								{errors.code?.type === "required" && <p className="error">Campo requerido </p>}
								{errors.code?.type === "maxLength" && <p className="error">Cantidad máxima de caracteres es de 40</p>}
							</FloatingLabel>

							<FloatingLabel label="Nombre">
								<Form.Control
									className="input--primary"
									placeholder="name"
									name="name"
									type="text"
									value={name}
									{...register("name", {
										required: true,
										maxLength: 40,
									})}
									onChange={e => {
										setName(e.target.value)
									}}
								/>
								{errors.name?.type === "required" && <p className="error">Campo requerido</p>}
								{errors.name?.type === "maxLength" && <p className="error">Cantidad máxima de caracteres es de 40</p>}
							</FloatingLabel>
                              <div className="form-select-row">
							<FloatingLabel label="Tipo de unidad">
								<Form.Select
									className="edit-prod-form-select"
									placeholder="Tipo de unidad"
									name="Tipo de unidad"
									type="text"
									value={unitType}
									{...register("unitType", {
										required: true,
									})}
									onChange={e => {
										setUnitType(e.target.value)
										const selectedUnit = allUnits.find(unit => unit._id === e.target.value)
										if (selectedUnit && (selectedUnit.name === "Unitarios" || selectedUnit.name === "Primaria")) {
											setShowWeightOrVolumeInput(false)
											setWeightOrVolume(null)
											setValue("weightOrVolume", null)
										} else {
											setShowWeightOrVolumeInput(true)
										}
									}}
								>
									<option
										value=""
										disabled
									>
										Seleccionar
									</option>
									{allUnits.map(unitType => (
										<option
											key={unitType._id}
											value={unitType._id}
										>
											{unitType.name}
										</option>
									))}
								</Form.Select>
								{errors.unitType?.type === "required" && <p className="error">Campo requerido</p>}
							</FloatingLabel>

							{showWeightOrVolumeInput && (
								<FloatingLabel
									label="Peso o Volumen"
								>
									<Form.Control
										className="input--primary"
										placeholder="Peso o Volumen"
										name="weightOrVolume"
										value={weightOrVolume}
										type="text"
										{...register("weightOrVolume", {
											required: true,
											maxLength: 40,
											pattern: /^[0-9]+$/, //Numeros
										})}
										onChange={e => {
											setWeightOrVolume(e.target.value)
										}}
									/>
									{errors.weightOrVolume?.type === "required" && <p className="error">Campo requerido </p>}
									{errors.weightOrVolume?.type === "maxLength" && <p className="error">Cantidad máxima de caracteres es de 40</p>}
									{errors.weightOrVolume?.type === "pattern" && <p className="error">Solo puede contener numeros</p>}
								</FloatingLabel>
							)}

							<FloatingLabel label="Proveedor">
								<Form.Select
									className="edit-prod-form-select"
									{...register("provider", {
										required: true,
									})}
									value={provider}
									onChange={e => setProvider(e.target.value)}
								>
									<option
										value=""
										disabled
									>
										Seleccionar
									</option>

									{providers.map(provider => (
										<option
											key={provider._id}
											value={provider._id}
										>
											{provider.name}
										</option>
									))}
									<option value="add">Agregar proveedor</option>
								</Form.Select>
								{errors.provider?.type === "required" && <p className="error">Campo requerido</p>}
							</FloatingLabel>
							</div>
							<FloatingLabel label="Marca">
								<Form.Control
									className="input--primary"
									placeholder="brand"
									name="brand"
									type="text"
									value={brand}
									{...register("brand", {
										maxLength: 40,
									})}
									onChange={e => {
										setBrand(e.target.value)
									}}
								/>
								{errors.brand?.type === "maxLength" && <p className="error">Cantidad máxima de caracteres es de 40</p>}
							</FloatingLabel>
							
							<FloatingLabel label="NBU">
								<Form.Control
									className="input--primary"
									placeholder="Nbu"
									name="ubn"
									type="text"
									value={ubn}
									{...register("ubn", {
										maxLength: 40,
										pattern: /^[0-9]+$/, //Numeros
									})}
									onChange={e => {
										setUbn(e.target.value)
									}}
								/>
								{errors.ubn?.type === "maxLength" && <p className="error">Cantidad máxima de caracteres es de 40</p>}
								{errors.ubn?.type === "pattern" && <p className="error">Solo puede contener números</p>}
							</FloatingLabel>
                           <div className="add-tag-product">
							<TagSelectorForm 
								tagSelected={tag}
								setTagSelected={setTag}
								isForBiochemicals={true}
							/>
                             </div>
							<div
								className="button-container">	
								<button
									children="Cancelar"
									type="submit"
									className="button-edit-text"
									onClick={() => {
										navigate("/productos")
									}}
								/>
								<button
									children="Guardar"
									type="submit"
									className="button-edit"
								/>
							</div>
						</div>
					</Form>
					</div>
				</div>
			</div>
			{variable ? (
				<AnimacionSuccessful
					errorBool={errorBool}
					avisomsg={avisomsg}
				/>
			) : (
				<></>
			)}
		</div>
	)
}

export default EditProducts
