import React, { useEffect, useState } from "react"
import SearchInput from "../AddOrder/Components/SearchInput"
import LabSelectionDropbox from "../AddOrder/Components/LabSelectionDropbox"
import axios from "axios"
import Cookies from "js-cookie"
import ProductsTable from "./Components/ProductsTable"
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful"
import { Button, Form, Pagination, Table, Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import OperationFinishedModal from "./Components/OperationFinishedModal"

export default function AddBiochemicalOrder() {
	const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI
	const navigate = useNavigate()
	const [search, setSearch] = useState("")
	const [allProducts, setAllProducts] = useState([])
	const [productsAndTagsAllowedId, setProductsAndTagsAllowedId] = useState([])
	const [selectedProducts, setSelectedProducts] = useState([])
	const [username, setUsername] = useState("")
	const [labsFromUser, setLabsFromUser] = useState([])
	const [userId, setUserId] = useState([])
	const [labSelected, setLabSelected] = useState()
	const [isAddOrdenButtonDisabled, setIsAddOrdenButtonDisabled] = useState(false)

	const [variable, setVariable] = useState(false)
	const [errorBool, setErrorBool] = useState(false)
	const [avisomsg, setAvisomsg] = useState("")

	const [productPageNumber, setProductPageNumber] = useState(0)
	const productsPerPage = 10
	const productsStartIndex = productPageNumber * productsPerPage
	const productsEndIndex = productsStartIndex + productsPerPage
	const productsPageCount = Math.ceil(allProducts.length / productsPerPage)

	// Estado para el modal
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isOperationFinishedModalOpen, setIsOperationFinishedModalOpen] = useState(false)

	// Almacena informacion de lotes que han sido editados o eliminados
	const [productsWithLotsEdited, setProductsWithLotsEdited] = useState([])

	useEffect(() => {
		;(async () => {
			const tokenCookies = Cookies.get("token")
			const response = await axios.get(`${BACK_APP_URI}/users/myProducts`, {
				headers: {
					Authorization: `${tokenCookies}`,
				},
			})
			setUsername(response.data.user.username)
			setLabsFromUser(response.data.user.labs)
			setUserId(response.data.user._id)
			setProductsAndTagsAllowedId([...response.data.user.productsAllowed_biochemicalRole, ...response.data.user.productTagsAllowed_biochemicalRole])
		})()
	}, [])

	useEffect(() => {
		;(async () => {
			if (labSelected) {
				const labSelectedData = labsFromUser.find(lab => lab._id === labSelected)
				const products = [...labSelectedData.products, ...labSelectedData.tags]
					.filter(
						product =>
							productsAndTagsAllowedId.some(productAllowedId => productAllowedId === product._id) && product.name.toUpperCase().includes(search.toUpperCase())
					)
					.map(product => ({ data: product, isTag: product.products ? true : false }))

				setAllProducts(products)
			}
		})()
	}, [labSelected, search])

	const handleClick = async () => {
		try {
			setIsAddOrdenButtonDisabled(true)

			if(selectedProducts.some(product => product.data.quantity - product.quantityRequested < 0)) {

				setVariable(true)
				setErrorBool(true)
				setAvisomsg("Cantidad insuficiente de productos")
				setTimeout(() => {
					setVariable(false)
				}, 2000)

				setIsAddOrdenButtonDisabled(false)
				return
			}

			await axios({
				method: "post",
				url: `${BACK_APP_URI}/pedidos/add`,
				data: {
					username: username,
					products: selectedProducts,
					labOrigin: labSelected,
					isFromBiochemicalRole: true,
				},
			})
				.then(data => {
					;(async () => {
						const productsWithLotsEditedArray = []
						function addLot(product, lot, itWasDeleted, quantitySubtracted) {
							if (productsWithLotsEditedArray.some(prod => prod?.data?._id === product.data._id)) {
								productsWithLotsEditedArray.map(prod => {
									if (prod => prod.data._id === product.data._id) {
										const newProduct = prod
										if (itWasDeleted) {
											newProduct.lotsDeleted.push(lot)
											return newProduct
										} else {
											newProduct.lotsEdited.push({ ...lot, quantitySubtracted: quantitySubtracted })
											return newProduct
										}
									} else {
										return prod
									}
								})
							} else {
								if (itWasDeleted) {
									productsWithLotsEditedArray.push({ 
										data: { _id: product.data._id, name: product.data.name }, 
										lotsDeleted: [lot], 
										lotsEdited: [] 
									})
								} else {
									productsWithLotsEditedArray.push({
										data: { _id: product.data._id, name: product.data.name },
										lotsEdited: [{ ...lot, quantitySubtracted: quantitySubtracted }],
										lotsDeleted: []
									})
								}
							}
						}
						for (const product of selectedProducts) {
							const productLots = product.data.lots.sort((a, b) => new Date(a.expireDate) - new Date(b.expireDate))
							let indice = 0
							do {
								if (product.quantityRequested >= productLots[indice].quantity) {
									product.quantityRequested -= productLots[indice].quantity
									addLot(product, productLots[indice], true)
									await axios.delete(`${BACK_APP_URI}/lotes/delete/${productLots[indice]._id}`)
									if (product.quantityRequested === 0) {
										break
									} else {
										indice++
									}
								} else {
									const updatedQuantity = productLots[indice].quantity - product.quantityRequested
									addLot(product, productLots[indice], false, product.quantityRequested)
									await axios.put(`${BACK_APP_URI}/lotes/edit/${productLots[indice]._id}`, {
										quantity: updatedQuantity,
										product: product.data._id,
									})
									product.quantityRequested = 0
								}
							} while (product.quantityRequested > 0 && indice < productLots.length)
							setProductsWithLotsEdited(productsWithLotsEditedArray)
							setIsOperationFinishedModalOpen(true)
						}
					})()



				})
				.catch(error => {
					// console.log(error)
					setErrorBool(true)
					setVariable(true)
					setAvisomsg(error.response.data.msg)
					setTimeout(() => {
						setVariable(false)
						setIsAddOrdenButtonDisabled(false)
					}, 3200)
				})
		} catch (error) {
			console.log(error)
		}
	}
	const handleFinalizarAutopedido = () => {
		setIsModalOpen(true) // Abre el modal
	}

	const handleModalConfirm = async () => {
		await handleClick() // Llama a la función para finalizar el autopedido
		setIsModalOpen(false) // Cierra el modal
	}

	const handleModalClose = () => {
		setIsModalOpen(false) // Cierra el modal
	}

	const handleAddOrRemoveItems = (item, quantity) => {
		if (selectedProducts.some(p => p.data._id === item.data._id)) {
			if (quantity === "" || parseInt(quantity) <= 0) {
				setSelectedProducts(prev => prev.filter(p => p.data._id !== item.data._id))
			} else {
				setSelectedProducts(prev =>
					prev.map(product => {
						if (product.data._id === item.data._id) {
							return { ...product, quantityRequested: quantity }
						} else {
							return product
						}
					})
				)
			}
		} else {
			if (quantity === "" || parseInt(quantity) <= 0) return
			setSelectedProducts(prev => [...prev, { ...item, quantityRequested: quantity }])
		}
	}

	const handleLabSelectedChange = labId => {
		setLabSelected(labId)
		setSelectedProducts([])
		setSearch("")
	}

	const handleProductsChangePage = selected => {
		setProductPageNumber(selected)
	}

	const handleFinishOperation = () => {
		setIsOperationFinishedModalOpen(false)
		setVariable(true)
		setErrorBool(false)
		setAvisomsg("Autopedido realizado con éxito!")
		setTimeout(() => {
			setVariable(false)
			navigate("/orders")
		}, 1200)
		setIsAddOrdenButtonDisabled(false)

	}

	return (
		<>
			<div className="main-container">
				<div className="header--container">
					<div className="order--header-cont">
						<div className="order--header-row">
							<h1 className="order--title">Carga autopedido de bioquímicos</h1>
							
						</div>
						<div className="order-row-select autopedido">
							<LabSelectionDropbox
								labSelected={labSelected}
								labsFromUser={labsFromUser}
								handleLabSelectedChange={handleLabSelectedChange}
							/>
							<SearchInput
								search={search}
								onChange={e => {
									setSearch(e.target.value)
									handleProductsChangePage(0)
								}}
							/>
						</div>
					</div>
				</div>
				<div className="card--container">
					<div className="table-container-new">
						<div className="table-striped table-bordered table-hover">
							{allProducts.length > 0 ? (
								<ProductsTable
									itemsEndIndex={productsEndIndex}
									itemsStartIndex={productsStartIndex}
									orderItems={allProducts}
									selectedProducts={selectedProducts}
									handleAddOrRemoveItems={handleAddOrRemoveItems}
								/>
							) : (
								<div style={{ textAlign: "center" }}>No hay productos bioquímicos asignados a este laboratorio.</div>
							)}
						</div>
					</div>

					{/* Paginación */}
					<div className="pagination-order pagination-all-tag">
						<Pagination>
							<Pagination.Prev
								onClick={() => handleProductsChangePage(productPageNumber > 0 ? productPageNumber - 1 : 0)}
								disabled={productPageNumber === 0}
							/>
							{Array.from({ length: productsPageCount }).map((_, index) => (
								<Pagination.Item
									className="pagination-item"
									key={index}
									active={index === productPageNumber}
									onClick={() => handleProductsChangePage(index)}
								>
									{index + 1}
								</Pagination.Item>
							))}
							<Pagination.Next
								onClick={() => handleProductsChangePage(productPageNumber < productsPageCount - 1 ? productPageNumber + 1 : productsPageCount - 1)}
								disabled={productPageNumber === productsPageCount - 1}
							/>
						</Pagination>
					</div>

					{/* Botones */}
					<div className="content-card-order">
						<Button
							className="btn-auto-order"
							children="Finalizar autopedido"
							onClick={handleFinalizarAutopedido}
							disabled={isAddOrdenButtonDisabled || labSelected === "" || !selectedProducts.length}
						/>
					</div>
				</div>
			</div>
			{/* Modal de confirmación */}
			<div > 
			<Modal 
				show={isModalOpen}
				onHide={handleModalClose}
			>
				<Modal.Header closeButton>
					<Modal.Title className="title-auto-order">Confirmar Autopedido</Modal.Title>
				</Modal.Header>
				<Modal.Body className="text-auto-order">
					<p>Usted va a realizar el siguiente pedido:</p>
					<ul>
						{selectedProducts.map(product => {
							return (
								<li key={product.data._id}>
									{product.data.name} - Cantidad: {product.quantityRequested}
								</li>
							)
						})}
					</ul>
				</Modal.Body>
				<Modal.Footer >
					<Button
						className="btn-auto-modal-text"
						onClick={handleModalClose}
					>
						Rechazar
					</Button>
					<Button
						className="btn-auto-modal"
						onClick={handleModalConfirm}
					>
						Aceptar
					</Button>
				</Modal.Footer>
			</Modal>
			</div>
			{isOperationFinishedModalOpen && (

				<OperationFinishedModal productsWithLotsEdited={productsWithLotsEdited} handleFinishOperation={handleFinishOperation}/>
			)}

			{/* Modal de estado */}
			{variable && (
				<AnimacionSuccessful
					errorBool={errorBool}
					avisomsg={avisomsg}
				/>
			)}
		</>
	)
}
