import React from "react"
import { Button, Modal } from "react-bootstrap"

export default function OperationFinishedModal({ productsWithLotsEdited, handleFinishOperation }) {
	return (
		<Modal show={true}>
			<Modal.Header>
				<Modal.Title>Autopedido Finalizado</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Se descontaron los siguientes lotes:</p>
				{productsWithLotsEdited.map((product, i) => {
					return (
						<>
							<p>{product.data.name}:</p>
							<ul>
								{product.lotsDeleted.map(lot => {
                  return <li>{lot.lotNumber} - Total Descontado: {lot.quantity}</li>
                })}
								{product.lotsEdited.map(lot => {
                  return <li>{lot.lotNumber} - Total Descontado: {lot.quantitySubtracted}</li>
                })}
								
							</ul>
              {i + 1 !== productsWithLotsEdited.length && <hr />}
              
						</>
					)
				})}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={handleFinishOperation}
				>
					Terminar Operación
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
