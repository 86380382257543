import axios from "axios"
import React, { useEffect, useState } from "react"
import { Card, FloatingLabel, Form, FormLabel, InputGroup } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import TagSelectorForm from "../Components/TagSelectorForm"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function ProductsForm({ unitTypesProduct, providers, productsData, handleSuccessfulMessage, handleUnsuccessfulMessage }) {
	const navigate = useNavigate()

	// Propiedades de producto
	const [code, setCode] = useState("")
	const [name, setName] = useState("")
	const [ubn, setUbn] = useState(null)
	const [brand, setBrand] = useState("")
	const [weightOrVolume, setWeightOrVolume] = useState(undefined)
	const [officeNumber, setOfficeNumber] = useState(0)
	const [unitType, setUnitType] = useState("")
	const [provider, setProvider] = useState("")
	const [tag, setTag] = useState(null) // Dejarlo en null para que el middleware funcione
	const [showWeightOrVolumeInput, setShowWeightOrVolumeInput] = useState(true)

	// Propiedades de Lote
	const [lotNumber, setLotNumber] = useState("")
	const [quantity, setQuantity] = useState("")
	const [lotQuantity, setLotQuantity] = useState("")
	const [expireDate, setExpireDate] = useState("")
	const [acquireDate, setAcquireDate] = useState("")

	const [productInputValue, setProductInputValue] = useState("")
	const [productsFound, setProductsFound] = useState([])
	const [productExistentId, setProductExistentId] = useState("")

	const [operation, setOperation] = useState("newProduct")

	const [showIncompleteFieldsBanner, setShowIncompleteFieldsBanner] = useState(false)

	let controller = new AbortController()

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()

	const handleChangeOperation = e => {
		setShowIncompleteFieldsBanner(false)
		setOperation(e.target.value)
	}

	const fetchProductByName = async name => {
		try {
			controller.abort()
			controller = new AbortController()

			const response = await axios.get(`${BACK_APP_URI}/productos/findByName/${name}`, { signal: controller.signal })

			return response.data
		} catch (err) {
			if (axios.isCancel(err)) {
				console.log("Request cancelled")
			}

			console.log("Error al obtener los productos:", err.message)
		}
	}

	const handleSearchProducts = async productToFind => {
		if (productToFind == "") {
			setProductsFound([])
		} else {
			const fetchedProducts = await fetchProductByName(productToFind)

			setProductsFound(fetchedProducts)
		}
	}

	// Función rebote para evitar fetchs innecesarios
	const debounce = (func, wait) => {
		let timeout
		return function (...args) {
			const context = this
			if (timeout) clearTimeout(timeout)
			timeout = setTimeout(() => func.apply(context, args), wait)
		}
	}

	// Aplicación de la función rebote
	const debouncedHandleSearchProducts = debounce(handleSearchProducts, 500)

	// Espera a un cambio en el input de productos
	useEffect(() => debouncedHandleSearchProducts(productInputValue), [productInputValue])

	const handleCheckExistentProduct = id => {
		if (id === productExistentId) {
			setProductExistentId("")
		} else {
			setProductExistentId(id)
		}
	}

	const fetchLotByNumber = async (lotNumber) => {
		try {
			const response = await axios.get(`${BACK_APP_URI}/lotes/findByNumber/${lotNumber}`);
			return response.data;
		} catch (err) {
			console.log("Error al obtener el lote:", err.message);
			return null; // Si no se encuentra el lote, retornamos null
		}
	}

    const handleLotNumberChange = async (e) => {
		const enteredLotNumber = e.target.value;
		setLotNumber(enteredLotNumber);
	
		if (enteredLotNumber !== "") {
			// Verifica si el lote ya existe al ingresar el número
			const existingLot = await fetchLotByNumber(enteredLotNumber);
	
			if (existingLot) {
				// Sobrescribir solo la fecha de vencimiento con la del lote existente
				setExpireDate(new Date(existingLot.expireDate)); 
				handleSuccessfulMessage("La fecha de vencimiento ha sido actualizada con la del lote existente.");
			} else {
				// Si no existe, permitir que el usuario ingrese una nueva fecha de vencimiento
				setExpireDate(""); 
			}
		}
	};


	
	const onSubmit = async () => {
		try {
			if (operation === "newProduct") {
				// Crear nuevo producto
				if (code === "" || 
					name === "" || 
					unitType === "" || 
					provider === "" || 
					lotNumber === "" || 
					acquireDate === "" || 
					expireDate === "" || 
					quantity === "" || 
					lotQuantity === "") {
					setShowIncompleteFieldsBanner(true);
					return;
				}
	
				const existingLot = await fetchLotByNumber(lotNumber);
	
				if (existingLot) {
					handleUnsuccessfulMessage("El número de lote ya existe para otro producto.");
					return;
				}
	
				const productResponse = await axios({
					method: "post",
					url: `${BACK_APP_URI}/productos/add`,
					data: {
						code,
						name,
						brand,
						ubn,
						unitType,
						provider,
						weightOrVolume,
						tag,
						acquireDate, 
						isNewProduct: true
					},
				});
	
				await axios({
					method: "post",
					url: `${BACK_APP_URI}/lotes/add`,
					data: {
						lotNumber,
						expireDate,
						acquireDate, 
						quantity,
						product: productResponse.data.product._id,
						lotQuantity,
						isNewProduct: true
					},
				});
			} else {
				// Producto existente
				if (productExistentId === "" || lotNumber === "" || lotQuantity === "" || acquireDate === "" || expireDate === "" || quantity === "") {
					setShowIncompleteFieldsBanner(true);
					return;
				}
	
				const existingLot = await fetchLotByNumber(lotNumber);
	
				if (existingLot) {
					await axios({
						method: "put",
						url: `${BACK_APP_URI}/lotes/associate`,
						data: {
							lotId: existingLot._id,
							productId: productExistentId,
							acquireDate, 
							isNewProduct: false
						},
					});
				} else {
					await axios({
						method: "post",
						url: `${BACK_APP_URI}/lotes/add`,
						data: {
							lotNumber,
							expireDate,
							acquireDate, 
							quantity,
							product: productExistentId,
							lotQuantity,
							isNewProduct: false
						},
					});
				}
			}
	
			handleSuccessfulMessage("Producto creado con éxito");
		} catch (error) {
			handleUnsuccessfulMessage(`Error al crear producto. ${error?.response?.data?.msg}`);
		}
	};
	
	
	

	return (
		<Form className="form-target" onSubmit={handleSubmit(onSubmit)}>
			<hr />
			<div className="flex-column">
				<div className="header-row">
					<h4 className="target-subtitle">Producto:</h4>
					<div className="target-select ">
						<input
							type="radio"
							name="newProduct"
							value="newProduct"
							id="newProduct"
							checked={operation === "newProduct"}
							onChange={handleChangeOperation}
						/>
						<label
							htmlFor="newProduct"
							className="radio-button"
						>
							{" "}
							Crear{" "}
						</label>
					

						<input
							type="radio"
							name="existentProduct"
							value="existentProduct"
							id="existentProduct"
							checked={operation === "existentProduct"}
							onChange={handleChangeOperation}
						/>
						<label
							htmlFor="existentProduct"
							className="radio-button"
						>
							{" "}
							Existente{" "}
						</label>
				</div>
				</div>
				{operation === "newProduct" ? (
					<>
						<FloatingLabel label="Código de producto">
							<Form.Control
								className="input--primary"
								placeholder="Codigo"
								name="Codigo"
								type="text"
								{...register("code", {
									required: true,
									maxLength: 40,
								})}
								onChange={e => {
									setCode(e.target.value)
								}}
							/>
							{errors.code?.type === "required" && <p className="error">Campo requerido </p>}
							{errors.code?.type === "maxLength" && <p className="error">Cantidad máxima de caracteres es de 40</p>}
						</FloatingLabel>
						<FloatingLabel label="Nombre">
							<Form.Control
								className="input--primary"
								placeholder="name"
								name="name"
								type="text"
								{...register("name", {
									required: true,
									maxLength: 40,
								})}
								onChange={e => {
									setName(e.target.value)
								}}
							/>
							{errors.name?.type === "required" && <p className="error">Campo requerido</p>}
							{errors.name?.type === "maxLength" && <p className="error">Cantidad máxima de caracteres es de 40</p>}
						</FloatingLabel>

						<FloatingLabel label="Tipo de unidad">
							<Form.Select
								className=""
								{...register("unitType", {
									required: true,
								})}
								value={unitType}
								onChange={e => {
									setUnitType(e.target.value)
									const selectedUnit = unitTypesProduct.find(unit => unit._id === e.target.value)
									if (selectedUnit && (selectedUnit.name === "Unitarios" || selectedUnit.name === "Primaria")) {
										setShowWeightOrVolumeInput(false)
									} else {
										setShowWeightOrVolumeInput(true)
									}
								}}
							>
								<option
									value=""
									disabled
								>
									Seleccionar
								</option>
								{unitTypesProduct.map(unitType => (
									<option
										key={unitType._id}
										value={unitType._id}
									>
										{unitType.name}
									</option>
								))}
							</Form.Select>
							{errors.unitType?.type === "required" && <p className="error">Campo requerido</p>}
						</FloatingLabel>

						{showWeightOrVolumeInput && (
							<FloatingLabel label="Peso o Volumen">
								<Form.Control
									className="input--primary"
									placeholder="Peso o Volumen"
									name="weightOrVolume"
									type="text"
									{...register("weightOrVolume", {
										required: true,
										maxLength: 40,
										pattern: /^[0-9]+$/, //Numeros
									})}
									onChange={e => {
										setWeightOrVolume(e.target.value)
									}}
								/>
								{errors.weightOrVolume?.type === "required" && <p className="error">Campo requerido </p>}
								{errors.weightOrVolume?.type === "maxLength" && <p className="error">Cantidad máxima de caracteres es de 40</p>}
								{errors.weightOrVolume?.type === "pattern" && <p className="error">Solo puede contener numeros</p>}
							</FloatingLabel>
						)}

						<FloatingLabel label="Proveedor">
							<Form.Select
								className=""
								{...register("provider", {
									required: true,
								})}
								value={provider}
								onChange={e => {
									if (e.target.value === "add") {
										navigate("/provider/add")
									}
									setProvider(e.target.value)
								}}
							>
								<option
									value=""
									disabled
								>
									Seleccionar
								</option>

								{providers.map(provider => (
									<option
										key={provider._id}
										value={provider._id}
									>
										{provider.name}
									</option>
								))}
								<option value="add">Agregar proveedor</option>
							</Form.Select>
							{errors.provider?.type === "required" && <p className="error">Campo requerido</p>}
						</FloatingLabel>
						<FloatingLabel label="Marca">
							<Form.Control
								className="input--primary"
								placeholder="brand"
								name="brand"
								type="text"
								{...register("brand", {
									maxLength: 40,
								})}
								onChange={e => {
									setBrand(e.target.value)
								}}
							/>
							{errors.brand?.type === "maxLength" && <p className="error">Cantidad máxima de caracteres es de 40</p>}
						</FloatingLabel>
						<FloatingLabel label="NBU">
							<Form.Control
								className="input--primary"
								placeholder="Nbu"
								name="ubn"
								type="text"
								{...register("ubn", {
									maxLength: 40,
									pattern: /^[0-9]+$/, //Numeros
								})}
								onChange={e => {
									setUbn(e.target.value)
								}}
							/>
							{errors.ubn?.type === "maxLength" && <p className="error">Cantidad máxima de caracteres es de 40</p>}
							{errors.ubn?.type === "pattern" && <p className="error">Solo puede contener números</p>}
						</FloatingLabel>
					</>
				) : productExistentId === "" ? (
					// Si no hay un producto seleccionado
					<Card
						className="input--primary mb-3"
						style={{ width: "100%" }}
					>
						<Card.Body>
							<Card.Text>Elegir Producto</Card.Text>
							<Form.Control
								className="input-primary"
								placeholder="Buscar Productos..."
								type="text"
								style={{
									marginBottom: ".5rem",
								}}
								value={productInputValue}
								onChange={e => setProductInputValue(e.target.value)}
							/>
							<div
								style={{
									width: "100%",
									maxHeight: "400px",
									overflowY: "scroll",
								}}
							>
								{productsFound.map(product => {
									return (
										<InputGroup
											style={{
												flexWrap: "nowrap",
											}}
											key={product.name}
										>
											<InputGroup.Checkbox
												checked={product._id === productExistentId}
												onChange={() => setProductExistentId(product._id)}
											/>
											<InputGroup.Text
												style={{
													whiteSpace: "normal",
													width: "100%",
													justifyContent: "center",
												}}
											>
												{product.name}
											</InputGroup.Text>
										</InputGroup>
									)
								})}
							</div>
						</Card.Body>
					</Card>
				) : (
					// Si hay un producto seleccionado
					productsFound.map(product => {
						if (product._id !== productExistentId) return

						return (
							<InputGroup
								style={{
									flexWrap: "nowrap",
								}}
								key={product.name}
							>
								<InputGroup.Checkbox
									checked={product._id === productExistentId}
									onChange={() => handleCheckExistentProduct(product._id)}
								/>
								<InputGroup.Text
									style={{
										whiteSpace: "normal",
										width: "100%",
										justifyContent: "center",
									}}
								>
									{product.name}
								</InputGroup.Text>
							</InputGroup>
						)
					})
				)}

				 {/* Número de Lote */}
				 <FloatingLabel label="Número de lote">
                <Form.Control
                    placeholder="lotNumber"
                    className="input--primary"
                    name="lotNumber"
                    type="text"
                    value={lotNumber}
                    {...register("lotNumber", {
                        required: true,
                        maxLength: 40,
                    })}
                    onChange={handleLotNumberChange} 
                />
                {errors.lotNumber?.type === "required" && <p className="error">Campo requerido</p>}
                {errors.lotNumber?.type === "maxLength" && <p className="error">Cantidad máxima de caracteres es de 40</p>}
            </FloatingLabel>

				<FloatingLabel label="Cantidad de lotes">
					<Form.Control
						className="input--primary"
						name="lotQuantity"
						placeholder="Cantidad de lotes"
						type="text"
						value={lotQuantity}
						{...register("lotQuantity", {
							required: true,
							pattern: /^[0-9]+$/,
						})} //numeros
						onChange={e => {
							setLotQuantity(e.target.value)
						}}
					/>
					{errors.lotQuantity?.type === "required" && <p className="error">Campo requerido</p>}
					{errors.lotQuantity?.type === "pattern" && <p className="error">Solo puede contener numeros</p>}
				</FloatingLabel>

				<FloatingLabel label="Cantidad de productos">
					<Form.Control
						className="input--primary"
						name="quantity"
						placeholder="Cantidad de productos"
						type="text"
						value={quantity}
						{...register("quantity", {
							pattern: /^[0-9]+$/,
							required: true,
						})} //numeros
						onChange={e => {
							setQuantity(e.target.value)
						}}
					/>
					{errors.quantity?.type === "required" && <p className="error">Campo requerido</p>}
					{errors.quantity?.type === "pattern" && <p className="error">Solo puede contener numeros</p>}
				</FloatingLabel>

				 {/* Fecha de ingreso */}
				 <div className="target-text-cont">
                <div>
                    <div className="target-text">Fecha de ingreso</div>
                    <DatePicker
                        placeholderText="Seleccionar"
                        className="input--primary form-control z-10"
                        name="acquireDate"
                        dateFormat="dd/MM/yyyy"
                        input
                        type="date"
                        selected={acquireDate}
                        onChange={(date) => setAcquireDate(date)} 
                        autoComplete="off"
                    />
                </div>

                {/* Fecha de vencimiento */}
                <div>
                    <div className="target-text">Fecha de vencimiento</div>
                    <DatePicker
                        placeholderText="Seleccionar"
                        className="input--primary form-control z-10"
                        name="expireDate"
                        dateFormat="dd/MM/yyyy"
                        input
                        type="date"
                        selected={expireDate}
                        onChange={(date) => setExpireDate(date)} 
                        autoComplete="off"
                    />
                </div>
            </div>

				{operation === "newProduct" && (
					<TagSelectorForm
						tagSelected={tag}
						setTagSelected={setTag}
						isForBiochemicals={true}
					/>
				)}

				{showIncompleteFieldsBanner && <p className="error">Faltan campos por completar </p>}

				<div
					className="button-container"
					
				>
					<button
						children="Cancelar"
						type="submit"
						className="btn-adp-text"
						onClick={() => {
							navigate("/productos")
						}}
					/>
					<button
						children="Guardar"
						type="submit"
						className="btn-adp"
					/>
				</div>
			</div>
		</Form>
	)
}
