import { useEffect, useState } from "react";
import UserForm from "./Components/UserForm";
import axios from "axios"
import Cookies from 'js-cookie';
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

const NewUser = () => {
  
	// Buscador de categorías de varios
	const [categoriesFound, setCategoriesFound] = useState([])

  useEffect(() => {
		;(async () => {
			try {
				const token = Cookies.get("token")
				const response = await axios.get(`${BACK_APP_URI}/categories`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				setCategoriesFound(response.data.categories)
			} catch (error) {
        console.log(error)
      }
		})()
	}, [])

  return <UserForm type="createUser" categoriesFound={categoriesFound}/>
};

export default NewUser;
