import React, { useState } from "react"
import LotsRow from "./LotsRow"
import iconAdd from "./../../../../Assets/Img/iconAdd2Edit.svg"


export default function MainRow({ isTag, item, selectedProducts, handleAddOrRemoveItems }) {
	const [openInfo, setOpenInfo] = useState(false)
	return (
		<>
			<tr className={`${!isTag && openInfo === false && "border-bottom-table"} `}>
				<td className="text-start boldBigText">{item.data.name}</td>

				{!isTag ? (
					<>
						<td className="quantity-td">
							<input
								style={{ width: "100px", display: "inline" }}
								type="number"
								min="1"
								value={selectedProducts.find(selectedProduct => selectedProduct.data._id === item.data._id)?.quantityRequested || ""}
								onChange={e =>
									handleAddOrRemoveItems(item, e.target.value)
								}
							/>
						</td>

						<td className={item.data.quantity - item.quantityToSend < 0 ? "text-red" : ""}>{`${item.data.quantity} ud/s.`}</td>
					</>
				) : (
					<>
						<td></td>
						<td></td>
					</>
				)}

				<td>{`${item.data.measure?.name || item.data.unitType?.name} ${item.data.weightOrVolume ? "(" + item.data.weightOrVolume + " c/u)" : ""}`}</td>
        {!isTag && (

          <td><button className="lotButton" onClick={() => setOpenInfo(prev => !prev)}><img src={iconAdd} alt="Icono ver mas"/></button></td>
        )}

			</tr>
			{openInfo && <LotsRow lots={item.data.lots}/>}
		</>
	)
}
