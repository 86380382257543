import React, { useEffect, useState } from "react"
import "./ExportOrderModal.css"
import { Modal, Form, Button, Dropdown } from "react-bootstrap"
import { DateRangePicker } from "react-dates"
import moment from "moment"
import Cookies from "js-cookie"
import axios from "axios"
import { utils, writeFile } from "xlsx"
import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink } from "@react-pdf/renderer"
import innovis_adobe_express from '../../Assets/Img/innovis_adobe_express.png';


const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function ExportOrderModal({ show, onHide, labs }) {
	const [ordersToExport, setOrdersToExport] = useState([]) // Guarda las ordenes con todos sus datos
	const [labsToFilter, setLabsToFilter] = useState([]) // Guarda los posibles laboratorios para filtrar
	const [filteredLab, setFilteredLab] = useState("") // Laboratorio seleccionado para filtrar
	const [productsToExport, setProductsToExport] = useState("") // Guarda los productos encontrados en las ordenes filtradas

	const [stateSelected, setStateSelected] = useState("Pendiente")

	const [openDropdownStateSelector, setOpenDropdownStateSelector] = useState(true)

	//---------------Date Range Picker------------------------------------------------

	moment.locale("es") // Establece el idioma para Moment.js
	const [startDate, setStartDate] = useState(moment().subtract(7, "days"))
	const [endDate, setEndDate] = useState(moment())
	const [focusedInput, setFocusedInput] = useState(null)

	const startDateTime = new Date(startDate).getTime()
	const endDateTime = new Date(endDate).getTime()

	const handleDateRangeChange = ({ startDate, endDate }) => {
		setStartDate(startDate)
		setEndDate(endDate)
	}

	const isOutsideRange = day => {
		return false // Habilita todos los días
	}

	useEffect(() => {
		; (async () => {
			const token = Cookies.get("token")
			const ordersResponse = await axios.get(`${BACK_APP_URI}/pedidos`, {
				headers: {
					Authorization: `${token}`,
				},
			})

			// Filtra si fue enviado o recibido
			const allOrders = ordersResponse.data.orders.filter(order => {

				if (stateSelected === "Pendiente") {
					return !order.hasOwnProperty("state")
				}

				return order?.state === stateSelected
			}).filter(order => {
				return labs.some(lab => lab?.name === order?.labOrigin?.name)
			})

			// Filtra por fecha seleccionada
			const ordersBetweenDates = allOrders.filter(order => {
				return new Date(order.createdAt).getTime() >= startDateTime && new Date(order.createdAt).getTime() <= endDateTime
			})

			// Filtra por laboratorio seleccionado (Opcional)
			if (!filteredLab == "") {
				const ordersFromLab = ordersBetweenDates.filter(order => order.labOrigin.name == filteredLab)

				setOrdersToExport(ordersFromLab)
			} else {
				setOrdersToExport(ordersBetweenDates)
			}
		})()
	}, [filteredLab, startDateTime, endDateTime, stateSelected, labs])

	const findProviderByName = async id => {
		const response = await axios.get(`${BACK_APP_URI}/proveedor/${id}`)

		return response.data
	}

	// Se encarga de los productos en las ordenes filtradas
	useEffect(() => {
		let productsArray = []

		ordersToExport.forEach(order => {
			if (order.products.length > 0) {
				order.products.forEach(product => {
					productsArray.push({
						id: product.data._id,
						orderCreatedAt: order.createdAt ? new Date(order.createdAt).toLocaleDateString() : "-",
						orderDeliveredAt: order.deliveredAt ? new Date(order?.deliveredAt).toLocaleDateString() : "",
						lab: order.labOrigin.name,
						code: product.data.code,
						productName: product.data.name,
						brand: product.data.brand,
						quantity: product.data.quantity,
						provider: product.data.provider.name,
					})
				})
			}

			if (order.otherProducts.length > 0) {
				order.otherProducts.forEach(otherProduct => {
					; (async () => {
						// Busca el nombre del proveedor a traves del id
						const provider = await findProviderByName(otherProduct.data.provider)

						productsArray.push({
							id: otherProduct.data._id,
							orderCreatedAt: order.createdAt ? new Date(order.createdAt).toLocaleDateString() : "-",
							orderDeliveredAt: order.deliveredAt ? new Date(order.deliveredAt).toLocaleDateString() : "-",
							lab: order.labOrigin.name,
							code: otherProduct.data.code,
							productName: otherProduct.data.name,
							brand: "-",
							quantity: otherProduct.data.quantity,
							provider: provider.name,
						})
					})()
				})
			}

			if (order.tags.length > 0) {
				order.tags.forEach(tag => {
					; (async () => {

						productsArray.push({
							id: tag.data._id,
							orderCreatedAt: order.createdAt ? new Date(order.createdAt).toLocaleDateString() : "-",
							orderDeliveredAt: order.deliveredAt ? new Date(order.deliveredAt).toLocaleDateString() : "-",
							lab: order.labOrigin.name,
							code: "-",
							productName: tag.data.name,
							brand: "-",
							quantity: tag.data.quantity,
							provider: "-",
							isTag: true
						})
					})()
				})
			}
		})

		setProductsToExport(productsArray)
	}, [ordersToExport])

	// Acción descarga del excel
	const exportOrdersExcel = () => {
		const ordersData = productsToExport.map(product => ({
			Sede: product.lab,
			Código: product.code,
			Nombre: product.productName,
			Cantidad: product.quantity,
			Marca: product.brand,
			Proveedor: product.provider,
			"Fecha realizado": product.orderCreatedAt,
			"Fecha entregado": product.orderDeliveredAt,
			Estado: stateSelected
		}))

		const worksheet = utils.json_to_sheet(ordersData)

		// Crear el objeto workbook y asignar el worksheet
		const workbook = utils.book_new()
		utils.book_append_sheet(workbook, worksheet, "Pedidos de sede")

		// Guardar el archivo Excel
		writeFile(workbook, `Lista_Pedidos_Sedes.xlsx`)
	}

	// Botón con la descarga del pdf
	const exportOrdersPDFButton = () => {
		const styles = StyleSheet.create({
			body: {
				paddingTop: 35,
				paddingBottom: 65,
				alignItems: "center",
			},
			header: {
				width: '90%',
				display: 'flex',
				flexDirection: "row",
				justifyContent: "space-between",
				marginBottom: 16,
				padding: '30px',
				alignItems: 'flex-start',
				backgroundColor: '#f4fbfa',
				borderRadius: '10px',
			},
			highlight: {
				color: "#fff",
				fontSize: 9,
				backgroundColor: "rgb(47, 161, 161)",
				padding: 5,
				paddingLeft: 10,
				paddingRight: 10,
				borderRadius: '3px',
				marginBottom: '5px',
				textTransform: 'uppercase',
			},
			membrete: {
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				gap: '5px',
			},
			membreteLogo: {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			},
			logo: {
				width: 80,
				height: 60,
			},
			infoLogo: {
				fontSize: 10,
				fontWeight: 'normal',
				padding: 5,

			},

			title: {
				fontSize: 28,
				fontWeight: 'extrabold',
				color: '#056a6c'
			},
			date: {
				textAlign: "center",
				fontSize: 10,
				fontWeight: 'bold',
			},
			table: {
				display: "table",
				width: "90%",
				borderRightWidth: 1,
				borderBottomWidth: 1,
				borderLeftWidth: 1,
				borderTopWidth: 1,
				borderRadius: '10px',
				borderColor: '#d5dbdb',
				textAlign: 'start',
			},
			tableRow: {
				flexDirection: "row",
			},
			tableRowTitle: {
				borderRadius: '5px',
				backgroundColor: '#029598',
				padding: '8px',
				flexDirection: "row",
				borderBottom: '0px',


			},
			tableCol: {

				width: "50%",
				borderStyle: 'solid',
				borderColor: '#d5dbdb',
			},

			tableColLeft: {
				borderRightWidth: 1,
			},

			tableCell: {
				textAlign: 'left',
				margin: "auto",
				marginTop: 4,
				marginBottom: 4,
				fontSize: 8,
				padding: 5,
			},
			tableTitle: {
				margin: "auto",
				marginTop: 2,
				marginBottom: 2,
				fontSize: 10,
				color: "white",
				padding: 5,
				textAlign: 'left',

			},
			footer: {
				position: "absolute",
				bottom: 20,
				left: 0,
				right: 0,
				textAlign: "center",
				fontSize: 9,
				padding: 10,
				borderTopWidth: 1,
				borderColor: '#cce8e8',
				color: '#056a6c',
			},
		})

		const Table = () => (
			<Document>
				<Page
					size="A4"
					style={styles.body}
				>
					<View style={styles.header}>
						<View style={styles.membrete}>
							<Text style={styles.title}>Pedidos sedes</Text>
							<Text style={styles.highlight}>
								{`PEDIDO: ${stateSelected.toUpperCase()}`}
							</Text>

							<Text style={styles.date}>Fecha: {new Date().toLocaleDateString()}</Text>
							<View style={styles.spacer} />
						</View>
						<View style={styles.membreteLogo}>
							<Image src={innovis_adobe_express} style={styles.logo} />
							<Text style={styles.infoLogo}>Laboratorio bioquímico integral</Text>

						</View>
					</View>
					<View style={[styles.table, { display: 'flex', justifyContent: 'flex-start', textAlign: 'start' }]}>
						<View style={styles.tableRowTitle}>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Sede</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Código</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Nombre</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Cantidad</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Marca</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Proveedor</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Creación</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Entrega</Text>
							</View>
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Estado</Text>
							</View>
						</View>

						{productsToExport.map(product => {
							return (
								<View
									style={[styles.tableRow, { borderTopWidth: 1, borderTopColor: "#d5dbdb", borderTopStyle: "solid" }]}

									key={product.id}
								>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.lab}</Text>
									</View>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.code}</Text>
									</View>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.productName}</Text>
									</View>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.quantity}</Text>
									</View>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.brand}</Text>
									</View>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.provider}</Text>
									</View>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.orderCreatedAt}</Text>
									</View>
									<View style={[styles.tableCol, styles.tableColLeft]}>
										<Text style={styles.tableCell}>{product.orderDeliveredAt}</Text>
									</View>
									<View style={styles.tableCol}>
										<Text style={styles.tableCell}>{stateSelected}</Text>
									</View>
								</View>
							)
						})}
					</View>
					{/* Footer */}
					<View style={styles.footer}>
                    <Text>INNOVIS | LABORATORIOS BIOQUÍMICOS</Text>
                </View>
				</Page>
			</Document>
		)

		return (
			<PDFDownloadLink
				document={<Table />}
				fileName="Lista_Pedidos_Sedes.pdf"
				style={{
					color: "#ffffff",
					textDecoration: "none",
				}}
			>
				{({ blob, url, loading, error }) => "Descargar PDF"}
			</PDFDownloadLink>
		)
	}

	return (
		<Modal className="modal-export-header"
			show={show}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={onHide}
		>
			<Modal.Header className="modal-export-cont" closeButton>
				<Modal.Title className="modal-export" id="contained-modal-title-vcenter">Exportar pedidos</Modal.Title>
			</Modal.Header>
			<div className="modal-body-cont">
				<Modal.Body className="modal-export-body">
					<div className="mb-1">
						<DateRangePicker
							startDate={startDate}
							startDateId="your_unique_start_date_id"
							endDate={endDate}
							endDateId="your_unique_end_date_id"
							onDatesChange={handleDateRangeChange}
							focusedInput={focusedInput}
							onFocusChange={focusedInput => setFocusedInput(focusedInput)}
							small
							displayFormat="DD/MM/YY" // Define el formato de visualización
							monthFormat="MMMM YYYY"
							weekDayFormat="dd"
							isOutsideRange={isOutsideRange}
						/>
					</div>



					<div className="modal-tabla">
						{openDropdownStateSelector &&
							["Pendiente", 'Aceptado', 'Aceptado sin Stock', 'Rechazado', 'Enviado', 'Recibido'].map(state => {
								return (
									<button
										size="sm"
										className={`dropdown-button ${stateSelected === state && "dropdown-button-selected"}`}
										onClick={() => setStateSelected(state)}
									>
										{state}
									</button>
								)
							})}
					</div>

					<Form.Select
						className="form-modal-export"
						onChange={e => {
							setFilteredLab(e.target.value)
						}}
					>
						<option value="">Todas las sedes</option>
						{labs.map(lab => {
							return (
								<option
									key={lab._id}
									value={lab?.name}
								>
									{lab?.name}
								</option>
							)
						})}
					</Form.Select>

					<div>
						Se encontraron <b>{ordersToExport.length}</b> pedidos para exportar.
					</div>
				</Modal.Body>
			</div>
			<Modal.Footer>
				<div className="btn-modal-export">
					<Button
						style={{ width: "10rem", marginRight: "1rem" }}
						className="btn-modal-order"
						onClick={exportOrdersExcel}
						disabled={!ordersToExport.length > 0}
					>
						Descargar excel
					</Button>
					<Button
						style={{ width: "10rem", marginRight: "1rem" }}
						className="btn-modal-order"
						disabled={!ordersToExport.length > 0}
					>
						{exportOrdersPDFButton()}
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
}
