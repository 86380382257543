import React, { Fragment } from "react"
import { Form, Table } from "react-bootstrap"
import MainRow from "./MainRow"
import ProductInTagRow from "./ProductInTagRow"

export default function ProductsTable({ orderItems, selectedProducts, itemsStartIndex, itemsEndIndex, handleAddOrRemoveItems }) {
	return (
		<Table responsive
			className="mb-3 rounded"
			borderless
		>
			<thead>
				<tr>
					<th className="text-start">Nombre</th>
          <th>C/ a Mandar (ud/s.)</th>
          <th className="quantity-available">Disponibles</th>
					<th>Medida</th>
					<th>Ver Más</th>
				</tr>
			</thead>

			<tbody>
				{orderItems.slice(itemsStartIndex, itemsEndIndex).map(item => {
					const tagProducts = item.isTag ? (item.data.products.length ? item.data.products : item.data.otherProducts) : []
					return (
						<Fragment key={item._id}>
							<MainRow item={item} isTag={tagProducts.length} selectedProducts={selectedProducts} handleAddOrRemoveItems={handleAddOrRemoveItems}/>

							{tagProducts &&
								tagProducts.map((product, i) => {
									const currentOrderSubitem = orderItems
										.find(item => {
											if (typeof item.quantityToSend == "object") {
												return item.quantityToSend?.some(subitem => subitem._id === product._id)
											} else {
												return false
											}
										})
										?.quantityToSend.find(subitem => subitem._id === product._id)

									return (
										<ProductInTagRow product={product} selectedProducts={selectedProducts} tagProducts={tagProducts} i={i} currentOrderSubitem={currentOrderSubitem} item={item} handleAddOrRemoveItems={handleAddOrRemoveItems}/>
									)
								})}
						</Fragment>
					)
				})}
			</tbody>
		</Table>
	)
}
