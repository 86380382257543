import React from "react"
import { Pagination } from "react-bootstrap"

export default function PaginationComponent({setProductPageNumber, productPageNumber, productsPageCount}) {
	return (
		<div className="pagination-order pagination-all-tag">
			<Pagination>
				<Pagination.Prev
					onClick={() => setProductPageNumber(productPageNumber > 0 ? productPageNumber - 1 : 0)}
					disabled={productPageNumber === 0}
				/>
				{Array.from({ length: productsPageCount }).map((_, index) => (
					<Pagination.Item
						className="pagination-item"
						key={index}
						active={index === productPageNumber}
						onClick={() => setProductPageNumber(index)}
					>
						{index + 1}
					</Pagination.Item>
				))}
				<Pagination.Next
					onClick={() => setProductPageNumber(productPageNumber < productsPageCount - 1 ? productPageNumber + 1 : productsPageCount - 1)}
					disabled={productPageNumber === productsPageCount - 1}
				/>
			</Pagination>
		</div>
	)
}
