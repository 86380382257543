import React from "react"
import { Form } from "react-bootstrap"

export default function SearchInput({ search, onChange }) {
	return (
		<Form
			className="all-tag-form"
			onSubmit={e => e.preventDefault()}
		>
			<Form.Group controlId="exampleForm.ControlInput1">
				<Form.Control
					className="all-tag-search"
					type="text"
					value={search}
					onChange={onChange}
					placeholder="Buscar..."
				/>
			</Form.Group>
		</Form>
	)
}
