import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from 'moment';
import axios from "axios";
import Cookies from 'js-cookie';
import '../Main.css';

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

const SedeMain = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    const updateUser = async (id, startDateOrder, endDateOrder) => {
        try {
            console.log('Intentando actualizar usuario con fechas:', {
                startDateOrder: startDateOrder.format('YYYY-MM-DD'),
                endDateOrder: endDateOrder.format('YYYY-MM-DD')
            });

            const response = await axios({
                method: "put",
                url: `${BACK_APP_URI}/users/edit/${id}`,
                data: {
                    role: "sede",
                    startDateOrder: startDateOrder.toISOString(),
                    endDateOrder: endDateOrder.toISOString()
                },
            });
            
            console.log('Respuesta de actualización:', response.data);
            navigate("/main");
            return true;
        } catch (error) {
            console.error("Error actualizando usuario:", error);
            return false;
        }
    };

    // Obtener datos de usuarios
    useEffect(() => {
        const getDataUser = async () => {
            try {
                const token = Cookies.get("token");
                console.log('Obteniendo usuarios con token:', token);
                
                const response = await axios.get(`${BACK_APP_URI}/users`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                });
                
                console.log('Usuarios obtenidos:', response.data.users);
                setUsers(response.data.users);
            } catch (error) {
                console.error("Error obteniendo usuarios:", error);
            }
        };
        
        getDataUser();
    }, []);

    // Manejar actualización de fechas
    useEffect(() => {
        const calculateNewDates = () => {
            const sede = Cookies.get('username');
            console.log('Username de sede:', sede);
            
            const sedeUser = users.find((user) => user.username === sede);
            console.log('Usuario encontrado:', sedeUser);

            if (!sedeUser) {
                console.log('No se encontró el usuario de la sede');
                return null;
            }

            const currentDate = moment();
            const existingEndDate = moment(sedeUser.endDateOrder);
            
            console.log('Fecha actual:', currentDate.format('YYYY-MM-DD'));
            console.log('Fecha fin existente:', existingEndDate.format('YYYY-MM-DD'));
            console.log('Diferencia en días:', currentDate.diff(existingEndDate, 'days'));

            // Si la fecha actual es posterior a la fecha de fin y ha pasado al menos 1 día
            if (currentDate.isAfter(existingEndDate) && currentDate.diff(existingEndDate, 'days') >= 1) {
                // Calcular nueva fecha de inicio (día 20 del mes actual)
                let newStartDate = moment()
                    .startOf('month')
                    .date(20);

                // Calcular nueva fecha de fin (día 4 del próximo mes)
                let newEndDate = moment()
                    .add(1, 'month')
                    .startOf('month')
                    .date(4);

                // Ajustar si cae en fin de semana
                const endDayOfWeek = newEndDate.day();
                if (endDayOfWeek === 6) { // Sábado
                    newEndDate = newEndDate.add(2, 'days');
                } else if (endDayOfWeek === 0) { // Domingo
                    newEndDate = newEndDate.add(1, 'days');
                }

                console.log('Nuevas fechas calculadas:', {
                    start: newStartDate.format('YYYY-MM-DD'),
                    end: newEndDate.format('YYYY-MM-DD')
                });

                return {
                    userId: sedeUser._id,
                    startDate: newStartDate,
                    endDate: newEndDate
                };
            }

            console.log('No se requiere actualización de fechas');
            return null;
        };

        const updateDateRange = async () => {
            if (users.length === 0) {
                console.log('No hay usuarios cargados todavía');
                return;
            }

            const newDates = calculateNewDates();
            
            if (newDates) {
                console.log('Procediendo a actualizar fechas');
                const success = await updateUser(
                    newDates.userId,
                    newDates.startDate,
                    newDates.endDate
                );

                if (success) {
                    setStartDate(newDates.startDate);
                    setEndDate(newDates.endDate);
                    console.log('Fechas actualizadas exitosamente');
                }
            }
        };

        updateDateRange();
    }, [users]);

    return (
        <div className="main-container">
            <div className="header-sede-main">
                <h1 className="title-sede-main">Nuevo pedido</h1>
                {startDate && endDate && (
                    <div className="date-info">
                        <p>Fecha inicio: {startDate.format('DD/MM/YYYY')}</p>
                        <p>Fecha fin: {endDate.format('DD/MM/YYYY')}</p>
                    </div>
                )}
                <Link to="/orders/add" className="mx-auto">
                    <Button className="button-sede-main">
                        Generar pedido
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default SedeMain;