import React, { useEffect, useState } from "react"
import { Form, Table } from "react-bootstrap"
import TooltipMesagge from "./TooltipMesagge"

export default function ProductsTable({
	allItems,
	itemsStartIndex,
	itemsEndIndex,
	selectedItems,
	handleAddOrRemoveItems,
	handleEditItems,
	errorMessages,
	role,
	categoriesAssigned, 
	isProduct,
}) {
	const [itemsToMap, setItemsToMap] = useState([])
	useEffect(() => {
		setItemsToMap(() => {
			if (!categoriesAssigned?.length) {
				return allItems.slice(itemsStartIndex, itemsEndIndex)
			} else {
				return allItems.filter(item => categoriesAssigned.some(category => category._id === item.category)).slice(itemsStartIndex, itemsEndIndex)
			}
		})
	}, [allItems, itemsStartIndex, itemsEndIndex, categoriesAssigned])

	const formatDisplayName = (name) => {
		return name
			.replace(/([a-z])([A-Z0-9])/g, '$1 $2') // Agrega espacio antes de mayúsculas o números
			.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Separar secuencias de mayúsculas
			.replace(/(\d+)/g, ' $1'); // Agregar espacio antes de números
	};
	
	
	return (
		<Table responsive className="table-striped table-bordered table-hover">
			<thead responsive> 
				<tr>
					<th></th>
					<th className="text-start">Nombre</th>
					<th>Cantidad
						{" "}
						<TooltipMesagge message={"Ingrese la cantidad en función de la unidad del producto (ej. mililitros), no en cantidades unitarias."}/>
					</th>
					<th>Unidad</th>
					{role !== "sede" && <th>Disponibles</th>}
				</tr>
			</thead>
			{itemsToMap.map(item => {
				const measure = item.measure?.name || item.unitType?.name
				
				return (
					<tbody key={item._id}>
						<tr>
							<td>
								<Form.Check
									className="d-inline p-1"
									value={item._id}
									type="checkbox"
									checked={selectedItems.some(itemSelected => itemSelected.id === item._id)}
									onChange={() => handleAddOrRemoveItems(item._id, item.isTag, isProduct)}
								/>
							</td>
							<td className="text-start">{formatDisplayName(item.name)}</td>
							<td>
								<input
									style={{ width: "25%", display: "inline" }}
									type="number"
									min="1"
									disabled={!selectedItems.some(itemSelected => itemSelected.id === item._id)}
									value={selectedItems.find(itemSelected => itemSelected.id === item._id)?.quantityRequested || ""}
									onChange={e => handleEditItems(item._id, e.target.value, isProduct)}
								/>
								{errorMessages[item._id] && <p className="error">{errorMessages[item._id]}</p>}
							</td>
							<td>{measure}</td>
							{/* Muestra la cantidad almacenada del producto */}
							{role !== "sede" && <td>{item?.isTag ? item.quantity : item.quantity * (item.weightOrVolume || 1)
							} </td>}
						</tr>
					</tbody>
				)
			})}
		</Table>
	)
}
