import axios from 'axios';
import Cookies from 'js-cookie';

// Crea una instancia de axios con la URL base de tu API
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000', // Reemplaza YOUR_PORT con el puerto de tu backend
    timeout: 5000, // Timeout de 5 segundos
    headers: {
        'Content-Type': 'application/json'
    }
});

// Interceptor para agregar el token a todas las peticiones
api.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Interceptor para manejar respuestas y errores
api.interceptors.response.use(
    (response) => response,
    (error) => {
        // Si el error es 401 (Unauthorized) o 403 (Forbidden)
        if (error.response?.status === 401 || error.response?.status === 403) {
            handleLogout();
        }
        return Promise.reject(error);
    }
);

// Función para manejar el logout
export const handleLogout = () => {
    // Eliminar cookies
    Cookies.remove('token');
    Cookies.remove('role');
    
    // Limpiar localStorage si estás usando algún dato ahí
    localStorage.clear();
    
    // Redireccionar a la página de login
    window.location.href = '/';
};

export default api;