import React, { useEffect, useState } from "react";
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
import innovis_adobe_express from "./../../../Assets/Img/innovis_adobe_express.png";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    alignItems: "center",
   
  },
  header: {
    width: '90%',
    display: 'flex',
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
    padding: '30px',
    alignItems: 'flex-start',
    backgroundColor: '#f4fbfa',
    borderRadius: '10px',
  },
  membrete: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '5px',
  },
  membreteLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pt_10: {
    paddingTop: 10,
  },
  logo: {
    width: 80,
    height: 60,
  },
  title: {
    fontSize: 28,
    fontWeight: 'extrabold',
    color: '#056a6c'
  },
  highlight: {
    color: "#fff",
    fontSize: 13,
    backgroundColor: "rgb(47, 161, 161)",
    padding: 1,
  },
  subtitle: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  campo: {
    fontSize: '12px',
    fontWeight: 'normal',
  },
  cardInfo: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  info: {
    fontSize: 12,
    fontWeight: 'normal',
    textAlign: "left",
    padding: 5,
    
  },
  infoLogo: {
    fontSize: 10,
    fontWeight: 'normal',
    padding: 5,
    
  },
  date: {
    textAlign: "center",
    fontSize: '12px',
    fontWeight: 'bold',
  },

  spacer: {
    width: 40,

  },
  table: {
    display: "table",
    width: "90%",
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderRadius: '10px',
    borderColor: '#d5dbdb',
    textAlign: 'start',
  },
  tableRow: {
    flexDirection: "row",
  },
  tableRowTitle: {
    borderRadius: '5px',
    backgroundColor: '#029598',
    padding: '8px',
    flexDirection: "row",
    borderBottom: '0px',
    

  },
  tableCol: {
    
    width: "50%", 
    borderStyle: 'solid',
    borderColor: '#d5dbdb',
  },

  tableColLeft: {
    borderRightWidth: 1, 
  },

  tableCell: {
    textAlign: 'left',
    margin: "auto",
    marginTop: 4,
    marginBottom: 4,
    fontSize: 10,
    padding: 5,
  },
  tableTitle: {
    margin: "auto",
    marginTop: 2,
    marginBottom: 2,
    fontSize: 12,
    color: "white",
    padding: 5,
    textAlign: 'left',
   
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 9,
    padding: 10,
    borderTopWidth: 1,
    borderColor: '#cce8e8',
    color: '#056a6c',
},
});





const OrderPDF = ({ orderInfo, orderProduct, orderOtherProducts, tabSection }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (tabSection === "biochemicals") {
      setProducts(orderProduct || []);
    } else {
      setProducts(orderOtherProducts || []);
    }
  }, [orderProduct, orderOtherProducts, tabSection]);

  const getTableTitle = () => {
  if (orderInfo.isAccepted) {
    return 'Cantidad Enviada';
  }
  return 'Pendiente de Aceptación';
};

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.header}>
          <View style={styles.membrete}>
            <Text style={styles.title}>
              {orderInfo.role === 'sede' ? 'PEDIDO SOLICITADO' : 'PEDIDO ENVIADO'}
            </Text>
            <View style={styles.pt_10} />
            <Text style={styles.subtitle}>Número de pedido: {orderInfo.nroPedido}</Text>
            <Text style={styles.subtitle}>Sede: {orderInfo.lab}</Text>
            <Text style={styles.date}>Fecha: {new Date().toLocaleDateString()}</Text>
            <View style={styles.spacer} />
          </View>
          <View style={styles.membreteLogo}>
            <Image src={innovis_adobe_express} style={styles.logo} />
            <Text style={styles.infoLogo}>Laboratorio bioquímico integral</Text>
            
          </View>
        </View>

        {/* Tabla de productos */}
        <View style={[styles.table, { display: 'flex', justifyContent: 'flex-start', textAlign: 'start'}]}>
          <View style={styles.tableRowTitle}>
            <View style={styles.tableCol}>
              <Text style={styles.tableTitle}>Nombre</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableTitle}>{getTableTitle()}</Text>
            </View>
          </View>

          {/* Iteramos sobre los productos */}
          {products.map((product) => {
            const isTagged = product.isTag ?? false;
            const name = product?.data?.name || 'Producto desconocido';

            let quantityToSend = 0;
            if (isTagged && Array.isArray(product.quantityToSend)) {
              quantityToSend = product.quantityToSend.reduce(
                (total, item) => total + (item?.quantityToSend ?? 0),
                0
              );
            } else {
              quantityToSend = product.quantityToSend ?? product.quantityRequested ?? 0;
            }

            return (
              <View
                style={[styles.tableRow,  { borderTopWidth: 1, borderTopColor: "#d5dbdb", borderTopStyle: "solid" }]}
                key={product.data?._id || product._id}
              >
                <View style={[styles.tableCol, styles.tableColLeft]}>
                  <Text style={styles.tableCell }>{name}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{quantityToSend}</Text>
                </View>
              </View>
            );
          })}
        </View>
        {/* Footer */}
        <View style={styles.footer}>
                    <Text>INNOVIS | LABORATORIOS BIOQUÍMICOS</Text>
                </View>
      </Page>
    </Document>
  );
};


export default OrderPDF;
