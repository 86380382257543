import React, { useState } from "react"
import iconAdd from "./../../../../Assets/Img/iconAdd2Edit.svg"
import { Fragment } from "react"
import { Form, Table } from "react-bootstrap"
import LotRow from "./LotRow"

export default function ItemRow({ item, select, deselect, itemSelected, isTag, isBioSection, isEven, allCategories }) {
	const [isOpen, setIsOpen] = useState(false)

	const handleSelect = () => {
		if (item._id === itemSelected) {
			deselect()
		} else {
			select(item)
		}
	}

	const handleOpenAndClose = () => {
		setIsOpen(prev => !prev)
	}

	const backgroundColor = isEven ? "backgound-veryLight" : ""

	const Td = ({ children }) => {
		return <td className={`${backgroundColor}`}>{isTag ? <b>{children}</b> : children }</td>
	}
	const formatDisplayName = (name) => {
		return name
			.replace(/([a-z])([A-Z0-9])/g, '$1 $2') // Agregar espacio antes de las mayúsculas o números
			.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Separar secuencias de mayúsculas
			.replace(/(\d+)/g, ' $1'); // Agregar espacio antes de los números
	};
	
	return (
		<>
			<tr
				key={item._id}
				className={` ${backgroundColor} text-color-light`}
			>
				<Td>
					{!isTag && (
						<Form.Check
							value={item._id}
							type="checkbox"
							checked={itemSelected === item._id}
							onChange={handleSelect}
						/>
					)}
				</Td>
				<Td>{item.code || "-"}</Td>
				<Td>{formatDisplayName(item.name)}</Td>

				<Td>{item.brand || "-"}</Td>


				{isBioSection && (
					<>
						
						<Td>{item.ubn || "-"}</Td>
					</>
				)}
				{!isBioSection && <Td>{item.category?.name || allCategories.find(category => category._id === item.category)?.name}</Td>}
				<Td>{item.quantity}</Td>
				<Td>{item.weightOrVolume || "-"}</Td>
				<Td>{item?.unitType?.name || item?.measure?.name}</Td>
				<Td>
					{item.provider && item.provider.name} {item.provider && item.provider.lastName}
				</Td>
				<Td>
					{(isTag || isBioSection) && (
						<button
							className="lotButton"
							onClick={handleOpenAndClose}
						>
							<img
								src={isOpen ? iconAdd : iconAdd}
								alt="ver mas información de los lotes"
							/>
						</button>
					)}
				</Td>
			</tr>

			{isTag &&
				isOpen &&
				(item.products.length
					? item.products.map(product => {
							return (
								<ItemRow
									item={product}
									deselect={deselect}
									select={select}
									itemSelected={itemSelected}
									isBioSection={isBioSection}
									isEven={isEven}
								/>
							)
					  })
					: item.otherProducts.map(product => {
							return (
								<ItemRow
									item={product}
									deselect={deselect}
									select={select}
									itemSelected={itemSelected}
									isBioSection={isBioSection}
									isEven={isEven}
								/>
							)
					  }))}

			{!isTag && isBioSection && isOpen && (
				<tr className={``}>
					<td
						colSpan={10}
						style={{
							padding: 0,
						}}
						className={backgroundColor}
					>
						<Table
							size="sm"
							style={{
								margin: 0,
							}}
							bordered
						>
							<tbody className={``}>
								<tr>
									<td>Numero de Lote</td>
									<td>Fecha de Ingreso</td>
									<td>Fecha de Vencimiento</td>
									<td>Cantidad</td>
									<td>Cantidad de lotes</td>
									<td>Editar</td>
								</tr>
								{item?.lots.map(lot => {
									return (
										<LotRow
											key={lot._id}
											lot={lot}
										/>
									)
								})}
							</tbody>
						</Table>
					</td>
				</tr>
			)}
		</>
	)
}
