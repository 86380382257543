import axios from "axios"
import Cookies from "js-cookie"
import React, { Fragment, useEffect, useState } from "react"
import { Accordion, Button, Modal } from "react-bootstrap"
import ListItemCheck from "./Components/ListItemCheck"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function TagAcceptanceModal({ show, handleClose, tagsToAccept, handleSuccessfulMessage, handleErrorMessage }) {
	const { newTags, tagsToEdit, tagsWithoutModification, tagsWithPoorData } = tagsToAccept
	
	const [providerNames, setProvidersNames] = useState([])
	const [unitsNames, setUnitsNames] = useState([])
	const [tagsNames, setTagsNames] = useState([])
	const [categoriesNames, setCategoriesNames] = useState([])

	useEffect(() => {
		;(async () => {
			// Obtiene todos los nombres de los proveedores
			const token = Cookies.get("token")
			const responseProvider = await axios({
				method: "get",
				url: `${BACK_APP_URI}/proveedor`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const providers = responseProvider.data.provider.map(provider => {
				return {
					id: provider._id,
					name: provider.name,
				}
			})

			setProvidersNames(providers)

			// Obtiene todas las unidades
			const responseUnit = await axios({
				method: "get",
				url: `${BACK_APP_URI}/unidades`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const units = responseUnit.data.units.map(unit => {
				return {
					id: unit._id,
					name: unit.name,
				}
			})

			setUnitsNames(units)

			// Obtiene todos los tags
			const responseTag = await axios({
				method: "get",
				url: `${BACK_APP_URI}/tags`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const tags = responseTag.data.tags.map(tags => {
				return {
					id: tags._id,
					name: tags.name,
				}
			})

			setTagsNames(tags)

			// Obtiene todos las categorías
			const responseCategories = await axios({
				method: "get",
				url: `${BACK_APP_URI}/categories`,
				headers: {
					Authorization: `${token}`,
				},
			})

			const categories = responseCategories.data.categories.map(category => {
				return {
					id: category._id,
					name: category.name,
				}
			})

			setCategoriesNames(categories)
		})()
	}, [])

	// La edición de productos fue aceptada y se manda a la base de datos
	const handleUpdateTags = async () => {
		try {
			
			for (const tag of newTags) {

				console.log(tag)
				await axios({
					method: "post",
					url: `${BACK_APP_URI}/tags/add`,
					data: tag,
				})
			}

			for (const tag of tagsToEdit) {
				// Convierte el array de propiedades en un objeto
				let dataToSend = {}

				tag.props.forEach(prop => {
					dataToSend[prop.propertyName] = prop.newValue
				})

				await axios({
					method: "put",
					url: `${BACK_APP_URI}/tags/edit/${tag.tagId}`,
					data: dataToSend,
				})
			}

			handleSuccessfulMessage("Edición completa")
			handleClose()
		} catch (error) {
			handleErrorMessage("Error al editar")
			handleClose()
		}
	}

	const ModalSections = [
		{
			title: "Nuevas Etiquetas",
			data: newTags,
			available: true,
		},
		{
			title: "Etiquetas a Editar",
			data: tagsToEdit,
			available: true,
		},
		{
			title: "Etiquetas sin Modificación",
			data: tagsWithoutModification,
			available: false,
		},
		{
			title: "Datos insuficientes",
			data: tagsWithPoorData,
			available: false,
		},
	]

	return (
		<Modal
			size="lg"
			show={show}
			onHide={handleClose}
			backdrop="static"
		>
			<Modal.Header>
				<Modal.Title className="italic">Creación de Etiquetas</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{ModalSections.map((section, index) => {
					// No se muestra si no tiene productos
					if (!section.data?.length > 0) return

					return (
						<div key={section.title}>
							<div className={`${!section.available && "color-gray"} flex flex-column gap-2`}>
								<h3 className={`${section.available ? "market-green" : "market-red"} market`}>{section.title}</h3>

								{section.title === "Etiquetas a Editar"
									? // Muestra diferencias de campos
									  section.data?.map(({ props: tag }) => {
											return (
												<div key={tag.name}>
													<Accordion defaultActiveKey="0">
														<Accordion.Item>
															<Accordion.Header>
																<div className="flex flex-column gap-2">
																	<div>Etiqueta: {tag[0].oldValue}</div>
																</div>
															</Accordion.Header>
															<Accordion.Body>
																{tag.some(prop => prop.state === "Edit") ? (
																	<div className="flex flex-column gap-2">
																		{tag.map(prop => {
																			if (prop.state === "Keep") return

																			// Traduce el nombre de la propiedad
																			let newPropertyName

																			switch (prop.propertyName) {
																				case "name":
																					newPropertyName = "Nombre"
																					break
																				case "measure":
																					newPropertyName = "Unidad"
																					break
																				case "provider":
																					newPropertyName = "Proveedor"
																					break
																				case "isProducts":
																					newPropertyName = "Tipo"
																					break
																				case "category":
																					newPropertyName = "Categoría"
																					break
																				default:
																					newPropertyName = prop.propertyName
																					break
																			}

																			let oldValue = prop.oldValue
																			let newValue = prop.newValue

																			// Busca el nombre del proveedor
																			if (prop.propertyName == "provider") {
																				oldValue = providerNames.find(provider => provider.id == oldValue).name
																				newValue = providerNames.find(provider => provider.id == newValue).name
																			}

																			// Busca la unidad
																			if (prop.propertyName == "measure") {
																				oldValue = unitsNames.find(unit => unit.id == oldValue).name
																				newValue = unitsNames.find(unit => unit.id == newValue).name
																			}

																			// Busca la categoría
																			if (prop.propertyName == "category") {
																				oldValue = categoriesNames.find(category => category.id == oldValue).name
																				newValue = categoriesNames.find(category => category.id == newValue).name
																			}

																			return (
																				<div
																					style={{
																						display: "flex",
																						gap: ".3rem",
																					}}
																					key={newPropertyName}
																				>
																					<div>{newPropertyName}:</div>{" "}
																					<div>
																						<b style={{ color: "#EE5858" }}>{oldValue}</b> → <b style={{ color: "#65D624" }}>{newValue}</b>
																					</div>
																				</div>
																			)
																		})}
																	</div>
																) : (
																	<div>No hay modificaciones</div>
																)}
															</Accordion.Body>
														</Accordion.Item>
													</Accordion>
												</div>
											)
									  })
									: section.data?.map(tag => {
											return (
												<div key={tag.name}>
													<Accordion defaultActiveKey="0">
														<Accordion.Item>
															<Accordion.Header>
																<div className="flex flex-column gap-2">
																	<div>Etiqueta: {tag.name}</div>
																</div>
															</Accordion.Header>
															<Accordion.Body>
																<ul>
																	<ListItemCheck
																		property={tag.name}
																		title={"Nombre:"}
																	/>
																	<li className={!tag.provider ? "color-red" : ""}>
																		Proveedor: {providerNames.find(provider => provider.id == tag.provider)?.name}
																	</li>
																	<li className={!tag.measure ? "color-red" : ""}>Unidad: {unitsNames.find(unit => unit.id == tag.measure)?.name}</li>
																	<ListItemCheck
																		property={tag.isProducts === true ? "Bioquímicos" : tag.isProducts === false ? "Varios" : undefined}
																		title={"Tipo:"}
																	/>
																	{tag.isProducts === false && (
																		<li className={!tag.category ? "color-red" : ""}>
																			Categoría: {categoriesNames.find(category => category.id == tag.category)?.name}
																		</li>
																	)}
																</ul>
															</Accordion.Body>
														</Accordion.Item>
													</Accordion>
												</div>
											)
									  })}
							</div>
							{index != ModalSections.length - 1 && <hr />}
						</div>
					)
				})}
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex">
					{newTags?.length || tagsToEdit?.length ? (
						<Button
							style={{ width: "10rem", marginRight: "1rem" }}
							className="btn--primary"
							variant="primary"
							onClick={handleUpdateTags}
						>
							Aceptar cambios
						</Button>
					) : null}
					<Button
						variant="secondary"
						onClick={handleClose}
					>
						Cerrar
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
}
