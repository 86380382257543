import React from "react"
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer"
import innovis_adobe_express from "./../../../Assets/Img/innovis_adobe_express.png"

const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		alignItems: "center",
	},
	header: {
		width: '90%',
		display: 'flex',
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 16,
		padding: '30px',
		alignItems: 'flex-start',
		backgroundColor: '#f4fbfa',
		borderRadius: '10px',
	},
	membrete: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		gap: '5px',
	},
	membreteLogo: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	logo: {
		width: 80,
		height: 60,
	},
	title: {
		fontSize: 28,
		fontWeight: 'extrabold',
		color: '#056a6c'
	},
	highlight: {
		color: "#fff",
		fontSize: 8,
		backgroundColor: "rgb(47, 161, 161)",
		padding: 5,
		borderRadius: '3px',
		marginBottom: '5px',
	},
	subtitle: {
		fontSize: 6,
		fontWeight: "bold",
	},
	campo: {
		fontSize: 10,
		fontWeight: "bold",
	},
	cardInfo: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	info: {
		fontSize: 10,
		fontWeight: 'normal',
		textAlign: "left",


	},
	infoLogo: {
		fontSize: 10,
		fontWeight: 'normal',
		padding: 5,

	},
	date: {
		textAlign: "center",
		fontSize: 10,
		fontWeight: 'bold',
	},
	spacer: {
		width: 40,
	},
	table: {
		display: "table",
		width: "90%",
		borderRightWidth: 1,
		borderBottomWidth: 1,
		borderLeftWidth: 1,
		borderTopWidth: 1,
		borderRadius: '10px',
		borderColor: '#d5dbdb',
		textAlign: 'start',
	},
	tableRow: {
		flexDirection: "row",
	},
	tableRowTitle: {
		borderRadius: '5px',
		backgroundColor: '#029598',
		padding: '8px',
		flexDirection: "row",
		borderBottom: '0px',


	},
	tableCol: {

		width: "25%",
		borderStyle: 'solid',
		borderColor: '#d5dbdb',
	},

	tableColFirst: {
		borderRightWidth: 1, 

	},
	tableCell: {
		textAlign: 'left',
		margin: "auto",
		marginTop: 4,
		marginBottom: 4,
		fontSize: 10,
		padding: 5,
	},
	tableTitle: {
		margin: "auto",
		marginTop: 2,
		marginBottom: 2,
		fontSize: 12,
		color: "white",
		padding: 5,
		textAlign: 'left',
	},
	footer: {
		position: "absolute",
		bottom: 20, 
		left: 0,
		right: 0,
		textAlign: "center",
		fontSize: 9,
		padding: 10,
		borderTopWidth: 1,
		borderColor: '#cce8e8',
		color: '#056a6c',
	},
})

const StockProductToPdf = ({ products, sender, isBiochemicalsSection }) => {
	const titulo = "ORDEN DE COMPRA"
	const highlight = "DOCUMENTO NO VÁLIDO COMO FACTURA"

	return (
		<Document>
			<Page style={styles.body}>
				<View style={styles.header}>
					<View style={styles.membrete}>
						<Text style={styles.title}>{titulo}</Text>
						<View style={styles.spacer} />
						<Text style={styles.highlight}>{highlight}</Text>

						<View style={styles.cardInfo}>
							<Text style={styles.campo}>{"Razón Social: "} </Text>
							<Text style={styles.info}>{sender.razonSocial}</Text>
						</View>
						<View style={styles.cardInfo}>
							<Text style={styles.campo}>{"CUIT: "}</Text>
							<Text style={styles.info}>{sender.cuit}</Text>
						</View>
						<View style={styles.cardInfo}>
							<Text style={styles.campo}>{"Dirección de envío: "} </Text>
							<Text style={styles.info}>{sender.address}</Text>
						</View>
						<View style={styles.cardInfo}>
							<Text style={styles.date}>Fecha: {new Date().toLocaleDateString()}</Text>
							<Text style={styles.info}></Text>
						</View>
						<View style={styles.spacer} />
					</View>
					<View style={styles.membreteLogo}>
						<Image
							src={innovis_adobe_express}
							style={styles.logo}
						/>
						<Text style={styles.infoLogo}>Laboratorio bioquímico integral</Text>

					</View>
				</View>
				<View style={styles.table}>
					<View style={styles.tableRowTitle}>
						<View style={styles.tableCol}>
							<Text style={styles.tableTitle}>Código</Text>
						</View>
						<View style={styles.tableCol}>
							<Text style={styles.tableTitle}>Producto</Text>
						</View>
						{isBiochemicalsSection && (
							<View style={styles.tableCol}>
								<Text style={styles.tableTitle}>Marca</Text>
							</View>
						)}
						<View style={styles.tableCol}>
							<Text style={styles.tableTitle}>Cantidad Pedida</Text>
						</View>
					</View>
					{products.map((product, index) => {
						return (
							<View
								style={styles.tableRow}
								key={index}
							>
								<View style={[styles.tableCol , styles.tableColFirst]}>
									<Text style={styles.tableCell}>{product.code || "-"}</Text>
								</View>
								<View style={[styles.tableCol , styles.tableColFirst]}>
									<Text style={styles.tableCell}>{product.name || "-"}</Text>
								</View>
								{isBiochemicalsSection && (
									<View style={[styles.tableCol , styles.tableColFirst]}>
										<Text style={styles.tableCell}>{product.brand || "-"}</Text>
									</View>
								)}
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product.quantityToOrder}</Text>
								</View>
							</View>
						)
					})}
				</View>
				{/* Footer */}
				<View style={styles.footer}>
					<Text>INNOVIS | LABORATORIOS BIOQUÍMICOS</Text>
				</View>
			</Page>
		</Document>
	)
}

export default StockProductToPdf
