
export function roundNumber(num) {
  return Math.round(num * 10) / 10
}

export function convertQuantityToSend(fromMeasure, toMeasure, quantity, weightOrVolume) {
  switch (fromMeasure) {
    case "PRIMARIA":
      return quantity

    case "UNITARIOS":
      return quantity

    case "LITROS":
      if (toMeasure === "LITROS") {
        return weightOrVolume * quantity
      } else if (toMeasure === "MILILITROS") {
        return (weightOrVolume / 1000) * quantity
      }

    case "MILILITROS":
      if (toMeasure === "MILILITROS") {
        return weightOrVolume * quantity
      } else if (toMeasure === "LITROS") {
        return weightOrVolume * 1000 * quantity
      }

    case "GRAMOS":
      if (toMeasure === "GRAMOS") {
        return weightOrVolume * quantity
      } else if (toMeasure === "KILOGRAMOS") {
        return weightOrVolume * 1000 * quantity
      }

    // case "KILOGRAMOS":
    // 	if (toMeasure === "KILOGRAMOS") {
    // 		return weightOrVolume * quantity
    // 	} else if (toMeasure === "GRAMOS") {
    // 		return (weightOrVolume / 1000) * quantity
    // 	}
  }
}