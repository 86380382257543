import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./Login.css";
import Logo from "../../Assets/Img/innovis_adobe_express.png";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode'; // Añadimos esta importación

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;
const TEST_MODE = process.env.REACT_APP_TEST === "true";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [error, setError] = useState(""); // Añadimos estado para errores
  const navigate = useNavigate();

  const validateToken = (token) => {
    try {
      const decodedToken = jwtDecode (token);
      const currentTime = Date.now() / 1000;
      
      // Verificar si el token ya está expirado o está muy cerca de expirar
      if (decodedToken.exp <= currentTime) {
        throw new Error("Token expirado");
      }
      
      return true;
    } catch (error) {
      console.error("Error validando token:", error);
      return false;
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(""); // Limpiar errores previos

    try {
      // Validaciones de formato
      if(username === "admin@admin.com" || username === "user@user.com") {
        if (!/\S+@\S+\.\S+/.test(username)) {
          setError("El correo electrónico no tiene un formato válido");
          return;
        }
      } else if (!/^[a-zA-Z0-9_-]+$/.test(username)) {
        setError("El nombre de usuario no es válido");
        return;
      }

      if (password.length < 8) {
        setError("La contraseña debe tener al menos 8 caracteres");
        return;
      }

      // Configurar interceptor para manejar errores
      const axiosInstance = axios.create();
      axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response?.status === 401) {
            setError("Credenciales inválidas");
          } else if (error.response?.status === 500) {
            setError("Error en el servidor. Por favor, intente más tarde");
          }
          return Promise.reject(error);
        }
      );

      const response = await axiosInstance.post(`${BACK_APP_URI}/login`, {
        username,
        password,
      });

      const { token, role } = response.data;

      // Validar el token antes de guardarlo
      if (!validateToken(token)) {
        setError("Error en la autenticación");
        return;
      }

      // Guardar datos en cookies con expiración
      const tokenExpiry = jwtDecode (token).exp;
      const expiryDate = new Date(tokenExpiry * 1000);

      Cookies.set("token", token, { expires: expiryDate });
      Cookies.set("role", role.name, { expires: expiryDate });
      Cookies.set("username", username, { expires: expiryDate });
      
      // No almacenar la contraseña en cookies por seguridad
      // Cookies.set("password", password); // Eliminar esta línea

      // Redireccionar según el rol
      if (role.name === "sede") {
        navigate("/main");
      } else if(role.name === "logistic") {
        navigate("/orders");
      } else {
        navigate("/home");
      }

    } catch (error) {
      console.error("Error en login:", error);
      
      // Si no hay un error específico establecido por el interceptor
      if (!error) {
        setError("Ha ocurrido un error al intentar iniciar sesión. Por favor, intente nuevamente.");
      }
    }
  };

  return (
    <div className="login">
      <div className="login--content">
        <img src={Logo} alt="logo" className="logo" />
        <Form className="register--form-container">
          {error && <div className="alert alert-danger">{error}</div>}
          <Form.Group className="mb-3 input--primary" controlId="formBasicName">
            <Form.Control
              className="custom-placeholder"
              type="text"
              placeholder="Nombre de usuario"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3 input--primary" controlId="formBasicSurname">
            <Form.Control
              className="custom-placeholder"
              type={isPasswordShown ? "text" : "password"}
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Check
            className="custom-form-check"
            inline
            label="Mostrar contraseña"
            checked={isPasswordShown}
            onChange={() => setIsPasswordShown(prev => !prev)}
          />
        </Form>
        <div className="btn--container-login">
          <button className="btn-login btn--primary-login" onClick={handleLogin}>
            Iniciar sesión
          </button>
        </div>
        {TEST_MODE && <p>test</p>}
      </div>
    </div>
  );
};

export default Login;