import React, { useEffect } from "react";
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
import innovis_adobe_express from '../../../Assets/Img/innovis_adobe_express.png'


import axios from "axios";
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        alignItems: "center",
    },
    header: {
        width: '90%',
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 16,
        padding: '30px',
        alignItems: 'flex-start',
        backgroundColor: '#f4fbfa',
        borderRadius: '10px',
    },
    membrete: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '5px',
    },
    membreteLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        width: 80,
        height: 60,
    },
    title: {
        fontSize: 28,
        fontWeight: 'extrabold',
        color: '#056a6c'
    },
    highlight: {
        color: "#fff",
        fontSize: 9,
        backgroundColor: "rgb(47, 161, 161)",
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: '3px',
        marginBottom: '5px',
    },
    subtitle: {
        fontSize: 6,
        fontWeight: "bold",
    },
    campo: {
        fontSize: 10,
        fontWeight: "bold",
    },
    cardInfo: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    info: {
        fontSize: 10,
        fontWeight: 'normal',
        textAlign: "left",


    },
    infoLogo: {
        fontSize: 10,
        fontWeight: 'normal',
        padding: 5,

    },
    date: {
        textAlign: "center",
        fontSize: 10,
        fontWeight: 'bold',
    },
    spacer: {
        width: 40,
    },
    table: {
        display: "table",
        width: "90%",
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderRadius: '10px',
        borderColor: '#d5dbdb',
        textAlign: 'start',
    },
    tableRow: {
        flexDirection: "row"
    },
    tableRowTitle: {
        borderRadius: '5px',
        backgroundColor: '#029598',
        padding: '8px',
        flexDirection: "row",
        borderBottom: '0px',


    },
    tableCol: {

        width: "20%",
        borderStyle: 'solid',
        borderColor: '#d5dbdb',
    },

    tableColFirst: {
        borderRightWidth: 1,
    },
    tableCell: {
        textAlign: 'left',
        margin: "auto",
        marginTop: 4,
        marginBottom: 4,
        fontSize: 8,
        padding: 5,
    },
    tableTitle: {
        margin: "auto",
        marginTop: 2,
        marginBottom: 2,
        fontSize: 12,
        color: "white",
        padding: 5,
        textAlign: 'left',
    },
    footer: {
        position: "absolute",
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: "center",
        fontSize: 9,
        padding: 10,
        borderTopWidth: 1,
        borderColor: '#cce8e8',
        color: '#056a6c',
        
    },

});


const ProviderProductsToPdf = ({ products }) => {


    return (
        <Document>
            <Page style={styles.body}>
                <View style={styles.header}>
                    <View style={styles.membrete}>
                        <Text style={styles.title}>Productos</Text>
                        <View style={styles.spacer} />
                        <Text style={styles.highlight}>
                        {`PROVEEDOR ${products.length > 0 ? products[0].provider.name.toUpperCase() : "SIN PROVEEDOR"}`}
                        </Text>


                        <View style={styles.cardInfo}>
                            <Text style={styles.date}>Fecha: {new Date().toLocaleDateString()}</Text>
                            <Text style={styles.info}></Text>
                        </View>
                        <View style={styles.spacer} />
                    </View>
                    <View style={styles.membreteLogo}>
                        <Image
                            src={innovis_adobe_express}
                            style={styles.logo}
                        />
                        <Text style={styles.infoLogo}>Laboratorio bioquímico integral</Text>

                    </View>
                </View>
                <View style={[styles.table, { display: 'flex', justifyContent: 'flex-start', textAlign: 'start' }]}>
                    <View style={styles.tableRowTitle}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableTitle}>Código</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableTitle}>Producto</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableTitle}>Marca</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableTitle}>Proveedor</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableTitle}>Cantidad</Text>
                        </View>

                    </View>


                    {products.map((product, index) => (
                        <View style={[styles.tableRow, { borderTopWidth: 1, borderTopColor: "#d5dbdb", borderTopStyle: "solid" }]}
                            key={index}>
                            <View style={[styles.tableCol, styles.tableColFirst]}>
                                <Text style={styles.tableCell}>{product.code}</Text>
                            </View>
                            <View style={[styles.tableCol, styles.tableColFirst]}>
                                <Text style={styles.tableCell}>{product.name}</Text>
                            </View>
                            <View style={[styles.tableCol, styles.tableColFirst]}>
                                <Text style={styles.tableCell}>{product.brand}</Text>
                            </View>
                            <View style={[styles.tableCol, styles.tableColFirst]}>
                                <Text style={styles.tableCell}>{product.provider.name}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{product.quantity}</Text>
                            </View>

                        </View>

                    ))}
                </View>
                {/* Footer */}
                <View style={styles.footer}>
                    <Text>INNOVIS | LABORATORIOS BIOQUÍMICOS</Text>
                </View>
            </Page>
        </Document>
    )

}

export default ProviderProductsToPdf;