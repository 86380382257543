import React from "react"

export default function LotsRow({ lots }) {
  function formattedDate(date) {
		const dateParts = date.split('-')
		const dayParts = dateParts[2].split('T')
		const day = dayParts[0]
		const month = dateParts[1]
		const year = dateParts[0]
		return(` ${day}/${month}/${year}`)
	}
  const rowStyle = {
    backgroundColor: "#f5f3f3",
    fontSize: "12px"
  }
  const titleStyle = {
		borderBottom: "1px solid #ffffff"
	}
	return (
		<>
			<tr style={titleStyle}>
				<td style={rowStyle}>Nro de Lote</td>
				<td style={rowStyle}>Fecha Ingreso</td>
				<td style={rowStyle}>Fecha Vencimiento</td>
				<td style={rowStyle}>Cantidad</td>
				<td style={rowStyle}>Cantidad de lotes</td>
			</tr>
			{lots.map((lot, i) => {
				return (
					<tr className={i + 1 === lots.length && "border-bottom-table"}>
						<td style={rowStyle}>{lot.lotNumber}</td>
						<td style={rowStyle}>{formattedDate(lot.acquireDate)}</td>
						<td style={rowStyle}>{formattedDate(lot.expireDate)}</td>
						<td style={rowStyle}>{lot.quantity}</td>
						<td style={rowStyle}>{lot.lotQuantity}</td>
					</tr>
				)
			})}
		</>
	)
}
