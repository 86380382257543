import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import axios from "axios"
import "./Users.css"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import moment from "moment"
import "moment/locale/es"
import Cookies from 'js-cookie';
import UserForm from "./Components/UserForm"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI


const EditUser = () => {
	const [isAdmin, setIsAdmin] = useState(false)
	const navigate = useNavigate()
	const { id } = useParams()
	const [fname, setFname] = useState("")
	const [lname, setLname] = useState("")
	const [position, setPosition] = useState("")
	const [role, setRole] = useState("")
	const [roleName, setRoleName] = useState("")
	const [email, setEmail] = useState("")
	const [username, setUsername] = useState("")
	const [extraOrder, setExtraOrder] = React.useState(false)
	const [biochemicalsNotAllowed, setBiochemicalsNotAllowed] = useState(false)
	const [isBiochemicalRole, setIsBiochemicalRole] = useState(false)

	// Buscador de categorías de varios
	const [categoriesFound, setCategoriesFound] = useState([])
	const [categoriesAssigned, setCategoriesAssigned] = useState([])

	// Buscador de categorías de varios
	const [labsAssigned, setLabsAssigned] = useState([])

	// Productos habilitados para pedir rol bioquimico
	const [productsAllowed, setProductsAllowed] = useState([])
	const [productsAndTagsAllowed, setProductsAndTagsAllowed] = useState([])

	// Date Range Picker
	moment.locale("es") // Establece el idioma para Moment.js
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const getCategoryById = async (id) => {
    const response = await axios.get(`${BACK_APP_URI}/categories/${id}`)

    return response.data
  }

	useEffect(() => {
		;(async () => {
			try {
				const token = Cookies.get("token")

				const response = await axios.get(`${BACK_APP_URI}/categories`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				setCategoriesFound(response.data.categories)

				await axios.get(`${BACK_APP_URI}/users/${id}`).then(async response => {
					setFname(response.data.fname)
					setLname(response.data.lname)
					setPosition(response.data.position)
					setRole(response.data.role)
					setRoleName(response.data.roleName)
					setUsername(response.data.username)
					setEmail(response.data.email)
					setRole(response.data.roleName)
					setExtraOrder(response.data.extraOrder)
					setBiochemicalsNotAllowed(response.data.biochemicalsNotAllowed)
					setStartDate(moment(response.data.startDateOrder))
					setEndDate(moment(response.data.endDateOrder))
					setProductsAndTagsAllowed([...response.data.productsAllowed_biochemicalRole, ...response.data.productTagsAllowed_biochemicalRole])
					setIsBiochemicalRole(response.data.biochemicalRole)

					// Procesa las categorías y labs
					const categories = await Promise.all(response.data.categoriesAssigned.map(category => getCategoryById(category)))

					setCategoriesAssigned(categories)
					setLabsAssigned(response.data.labs)
				})
			} catch (error) {
        console.log(error)
      }
		})()

	}, [])

	return <UserForm type="editUser" id={id} fname={fname} lname={lname} position={position} role={role} roleName={roleName} email={email} username={username} extraOrder={extraOrder} biochemicalsNotAllowed={biochemicalsNotAllowed} categoriesFound={categoriesFound} categoriesAssigned={categoriesAssigned} labsAssigned={labsAssigned} startDate={startDate} endDate={endDate} productsAndTagsAllowed={productsAndTagsAllowed} isBiochemicalRole={isBiochemicalRole}/>
}

export default EditUser
