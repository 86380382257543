import React from "react"
import { Button, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"

export default function ProductDeleteHandlerModal({ ordersThatContainsProduct, showProductDeleteHandlerModal, closeProductDeleteHandlerModal }) {
	return (
		<Modal
			show={showProductDeleteHandlerModal}
			onHide={closeProductDeleteHandlerModal}
		>
			<Modal.Header closeButton>
				<Modal.Title>Eliminación de producto no permitida</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Producto asociado a un pedido activo:
				<div
					style={{
						display: "flex",
						gap: ".5rem",
						flexWrap: "wrap", 
					}}
				>
					{ordersThatContainsProduct.map(order => {
						return <Link key={order._id} to={`/orders/edit/${order._id}`}>Pedido N°{order.nroPedido}</Link>
					})}
				</div>
			</Modal.Body>
			<Modal.Footer> 
				<Button
					variant="secondary"
					onClick={closeProductDeleteHandlerModal}
				>
					Cerrar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
