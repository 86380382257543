import React, { useState } from "react"
import LotsRow from "./LotsRow"
import iconAdd from "./../../../../Assets/Img/iconAdd2Edit.svg"

export default function ProductInTagRow({ product, tagProducts, selectedProducts, item, i, currentOrderSubitem, handleAddOrRemoveItems }) {
	const [openInfo, setOpenInfo] = useState(false)
	return (
		<>
			<tr
				key={product._id}
				className={i + 1 === tagProducts.length && !openInfo && "border-bottom-table"}
			>
				<td className="text-start">{product.name}</td>
				<td className="quantity-td">
					<input
						style={{ width: "100px", display: "inline" }}
						type="number"
						min="1"
						value={selectedProducts.find(selectedProduct => selectedProduct.data._id === product._id)?.quantityRequested || ""}
						onChange={e => handleAddOrRemoveItems({data: product}, e.target.value)}
					/>
				</td>
				<td className={currentOrderSubitem?.currentQuantity - currentOrderSubitem?.quantityToSend < 0 ? "text-red" : ""}>{product.quantity} ud/s.</td>
				<td>{`${product.unitType.name} ${product.weightOrVolume ? "(" + product.weightOrVolume + " c/u)" : ""}`}</td>
				<td>
					<button
						className="lotButton"
						onClick={() => setOpenInfo(prev => !prev)}
					>
						<img
							src={iconAdd}
							alt="Icono ver mas"
						/>
					</button>
				</td>
			</tr>
			{openInfo && <LotsRow lots={product.lots} />}
		</>
	)
}
