import axios from "axios"
import React, { useEffect, useState } from "react"
import { Button, Card, CloseButton, FloatingLabel, Form, InputGroup, Modal } from "react-bootstrap"
import AnimacionSuccessful from "../../../AnimacionSuccessful/animacionSuccessful"

export default function AddProductToOrderModal({ show, setShow, tabSection, lab, productsFromOrder, otherProductsFromOrder, orderId }) {
	const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI
	const [productInputValue, setProductInputValue] = useState("")
	const [productsFound, setProductsFound] = useState([])
	const [productsSelected, setProductsSelected] = useState([])

	const [variable, setVariable] = useState(false)
	const [errorBool, setErrorBool] = useState(false)
	const [avisomsg, setAvisomsg] = useState("")


	const formatDisplayName = (name) => {
		return name
			.replace(/([a-z])([A-Z0-9])/g, '$1 $2') // Agrega espacio antes de mayúsculas o números
			.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Separar secuencias de mayúsculas
			.replace(/(\d+)/g, ' $1'); // Agregar espacio antes de números
	};
	
	useEffect(() => {
		if (productInputValue == "") {
			setProductsFound([])
		} else {
			let productsFiltered = []

			function filterByInput(productArr, tagArr, isProducts) {
				return [
					...productArr,
					...tagArr
						.filter(tag => (isProducts ? tag.isProducts : !tag.isProducts))
						.map(tag => {
							return { ...tag, isTag: true }
						}),
				]
					.filter(product => {
						const orderArr = isProducts ? productsFromOrder : otherProductsFromOrder
						return !orderArr.some(orderProduct => orderProduct.data._id === product._id)
					})
					.filter(item => {
						if (item.isTag) {
							return formatDisplayName(item.name).toUpperCase().includes(productInputValue.toUpperCase())
						} else {
							return formatDisplayName(item.name).toUpperCase().includes(productInputValue.toUpperCase()) || item.code.toUpperCase().includes(productInputValue.toUpperCase())
						}
					})
			}

			if (tabSection === "biochemicals") {
				productsFiltered = filterByInput(lab.products, lab.tags, true)
			} else {
				productsFiltered = filterByInput(lab.otherProducts, lab.tags, false)
			}

			setProductsFound(productsFiltered)
		}
	}, [productInputValue])

	const handleSelectProduct = product => {
		if (tabSection === "biochemicals") {
			const newProduct = {
				data: product,
				quantityRequested: 1,
				isTag: product.isTag,
			}
			setProductsSelected(prev => [...prev, newProduct])
		} else {
			const newProduct = {
				data: product,
				quantityRequested: 1,
				isTag: product.isTag,
			}
			setProductsSelected(prev => [...prev, newProduct])
		}
	}

	const handleEditQuantity = (productToEdit, newQuantity) => {
		setProductsSelected(prev =>
			prev.map(productSelected => {
				if (productSelected.data === productToEdit.data) {
					return { ...productSelected, quantityRequested: parseInt(newQuantity) }
				} else {
					return productSelected
				}
			})
		)
	}

	const handleRemoveProduct = product => {
		setProductsSelected(prev => {
			const filtered = prev.filter(prevProduct => prevProduct._id !== product._id)

			return filtered
		})
	}

	const handleCloseModal = () => {
		setShow(false)
		setTimeout(() => {
			setProductInputValue("")
			setProductsFound([])
			setProductsSelected([])
		}, 500)
	}

	const handleEditOrderWithNewProducts = async () => {
		try {
			if (tabSection === "biochemicals") {
				const productsToOrder = [...productsFromOrder, ...productsSelected].filter(product => !product.isTag)
				const productTagsToOrder = [...productsFromOrder, ...productsSelected].filter(product => product.isTag)

				await axios({
					method: "put",
					url: `${BACK_APP_URI}/pedidos/edit/${orderId}`,
					data: {
						products: productsToOrder,
						tags: productTagsToOrder,
					},
				})
			} else {
				const otherProductsToOrder = [...otherProductsFromOrder, ...productsSelected].filter(product => !product.isTag)
				const otherProductTagsToOrder = [...otherProductsFromOrder, ...productsSelected].filter(product => product.isTag)

				await axios({
					method: "put",
					url: `${BACK_APP_URI}/pedidos/edit/${orderId}`,
					data: {
						otherProducts: otherProductsToOrder,
						tags: otherProductTagsToOrder,
					},
				})
			}

			setShow(false)
			setVariable(true)
			setErrorBool(false)
			setAvisomsg("Productos Añadidos Correctamente")
		} catch (error) {
			setShow(false)
			setErrorBool(true)
			setVariable(true)
			setAvisomsg(error?.response?.data?.msg)

			setTimeout(() => {
				setVariable(false)
			}, 3200)

			console.log(".:Mensaje de error:.")
			console.log(error.response.data.msg)
		}
	}
	return (
		<>
			<Modal
				show={show}
				onHide={handleCloseModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>Añadir Productos</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<FloatingLabel
							controlId="floatingInput"
							label="Buscar Nombre o Código"
						>
							<Form.Control
								className="input-primary"
								placeholder="Buscar..."
								type="text"
								value={productInputValue}
								onChange={e => setProductInputValue(e.target.value)}
							/>
						</FloatingLabel>

						<div
							style={{
								maxHeight: "200px",
								overflowY: "auto",
							}}
							className="mb-3"
						>
							{productsFound.map(product => {
								if (productsSelected && productsSelected.some(productSelected => productSelected.data._id === product._id)) return

								return (
									<InputGroup
										style={{
											flexWrap: "nowrap",
										}}
										key={product._id}
									>
										<InputGroup.Checkbox onChange={() => handleSelectProduct(product)} />
										<InputGroup.Text
											style={{
												whiteSpace: "normal",
												width: "100%",
												justifyContent: "center",
											}}
										>
											{product.name}
										</InputGroup.Text>
									</InputGroup>
								)
							})}
						</div>

						{productsSelected.length > 0 ? (
							<Card
								className="input--primary"
								style={{
									width: "100%",
									maxHeight: "200px",
									overflow: "auto",
								}}
							>
								<Card.Header as="h6">Productos Seleccionados</Card.Header>
								<Card.Body>
									<Form.Group>
										{productsSelected.map(product => {
											return (
												<div
													key={product.data._id}
													style={{
														display: "flex",
														textAlign: "start",
														justifyContent: "space-between",
														alignContent: "center",
														marginBottom: "0.5rem",
													}}
												>
													<CloseButton
														onClick={() => handleRemoveProduct(product)}
														className="remove-button"
													/>
													<div
														style={{
															flexGrow: "1",
														}}
													>
														{product.data.name}
													</div>
													<input
														value={product.quantityRequested}
														onChange={e => handleEditQuantity(product, e.target.value)}
														type="number"
													/>
												</div>
											)
										})}
									</Form.Group>
								</Card.Body>
							</Card>
						) : null}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={handleCloseModal}
					>
						Cerrar
					</Button>
					<Button
						variant="primary"
						onClick={handleEditOrderWithNewProducts}
						disabled={!productsSelected.length}
					>
						Guardar Cambios
					</Button>
				</Modal.Footer>
			</Modal>

			{variable && (
				<AnimacionSuccessful
					errorBool={errorBool}
					avisomsg={avisomsg}
					refreshPage={() => {
						window.location.reload()
						setVariable(false)
					}}
				/>
			)}
		</>
	)
}
