import React from "react";
import { Route, Routes } from "react-router-dom";
import AddOrder from "./AddOrder/AddOrder";
import AllOrders from "./AllOrders/AllOrders";
import EditOrder from "./EditOrder/EditOrder";
import "./Order.css"
import AddBiochemicalOrder from "./AddBiochemicalOrder/AddBiochemicalOrder";

export default function Orders() {
  return (
    <Routes>
      {/* Ruta exacta para ver todos los pedidos */}
      <Route exact path="/" element={<AllOrders/>} />
      
      {/* Ruta exacta para generar un pedido */}
      <Route exact path="/add" element={<AddOrder/>}/>

      {/* Ruta exacta para editar un pedido */}
      <Route exact path="/edit/:id" element={<EditOrder/>}/>

      {/* Ruta exacta para agregar autopedido de bioquimicos */}
      <Route exact path="/addBiochemicalOrder" element={<AddBiochemicalOrder/>}/>
    </Routes>
  );
}
